<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">查询日期</div>
                <el-date-picker v-model="date" :editable="false" align="right" type="date" placeholder="选择日期" style="width:165px;" :shortcuts="shortcuts" :default-value="new Date()" :clearable="false" :picker-options="pickerOptions"> </el-date-picker>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
        </div>

        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="mini" :max-height="windowHeight-200" v-loading="isload" >
                <el-table-column align="center" prop="dcRoomNumber" label="房间号" width="120"></el-table-column>
                <el-table-column align="center" prop="dcCustomerName" label="客户姓名" width="120"></el-table-column>
                <el-table-column align="center" prop="dnPrice" label="入住价格" width="120"></el-table-column>
                <el-table-column align="center" prop="dnDepositPrice" label="入住押金" width="100"></el-table-column>
                <el-table-column align="center" prop="dnDay" label="入住天数" width="100"></el-table-column>
                <el-table-column align="center" prop="dtInTime" label="入住时间" width="140"></el-table-column>
                <el-table-column align="center" prop="dtOutTime" label="预离时间" width="140"></el-table-column>
                <el-table-column align="center" prop="dtBackTime" label="退房时间" width="140"></el-table-column>
                <el-table-column align="center" label="是否当天入住" width="100">
                    <template #default="scope">
                        <div class="fc-success fw-b" v-show="GetIsInTime(scope.row.dtInTime)">当天入住</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否退房" width="100">
                    <template #default="scope">
                        <div class="fc-success fw-b" v-show="scope.row.dnIsBack == 1" >已退房</div>
                        <div class="fc-error fw-b" v-show="scope.row.dnIsBack == 0">未退房</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import $utils from '../../utils/utils';
import $webapi from "../../utils/webapi";
export default {
    name:"room",
    created(){
        this.bindData();
    },
    data(){
        return{
            date:"",
            isload:false,
            windowHeight: document.documentElement.clientHeight,
            tableData: [],
        }
    },
    methods:{
        GetIsInTime(dates){
            let _this = this;
            console.log($utils.getDateFC(dates) +" = "+ $utils.getDateFCWithDate(_this.date));
            return $utils.getDateFC(dates) == $utils.getDateFCWithDate(_this.date)
        },
        GoSearch(){
            this.bindData();
        },
        ReSearch(){
            this.date = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getCustomerCount($utils.getDateFCWithDate(this.date)).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
        };
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;

        }
        .form{
            padding: 30px;
            padding-top: 0;
        }
    }
</style>