<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">客户姓名</div>
                <el-input v-model="dcCustomerName" placeholder="输入客户姓名" style="width:120px;" :clearable="true" size="medium"></el-input>
            </div>
            <div class="search">
                <div class="label">房间号</div>
                <el-input v-model="dcRoomNumber" placeholder="输入房间号" style="width:120px;" :clearable="true" size="medium"></el-input>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
        </div>

        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="mini" :max-height="windowHeight-250" v-loading="isload" >
                <el-table-column fixed align="center" prop="dnCustomerID" label="客户ID" width="100"></el-table-column>
                <el-table-column align="center" prop="dcCustomerName" label="客户姓名" width="120"></el-table-column>
                <el-table-column align="center" prop="dcCome" label="来源" width="120"></el-table-column>
                <el-table-column align="center" prop="dcPhone" label="手机号" width="120"></el-table-column>
                <el-table-column align="center" prop="dcCodeType" label="证件类型" width="120"></el-table-column>
                <el-table-column align="center" prop="dcCode" label="证件号" width="150"></el-table-column>


                <el-table-column align="center" prop="room.dcRoomNumber" label="房间号" width="120"></el-table-column>
                <el-table-column align="center" prop="room.dcRoomName" label="房型" width="120"></el-table-column>
                <el-table-column align="center" prop="dnPrice" label="入住价格" width="120"></el-table-column>
                <el-table-column align="center" prop="dnDepositPrice" label="入住押金" width="100"></el-table-column>
                <el-table-column align="center" prop="dnDay" label="入住天数" width="100"></el-table-column>
                <el-table-column align="center" prop="dtInTime" label="入住时间" width="140"></el-table-column>
                <el-table-column align="center" prop="dtOutTime" label="预离时间" width="140"></el-table-column>
                <el-table-column align="center" prop="dtBackTime" label="退房时间" width="140">
                    <template #default="scope">
                        <div v-if="scope.row.dnIsBack == 1">{{scope.row.dtBackTime}}</div>
                        <div v-else>未退房</div>
                    </template>
                </el-table-column>
                
                <el-table-column align="center" fixed="right" label="操作" width="100">
                    <template #default="scope">
                        <el-button type="text" size="small" @click="ShowLog(scope.row)">查看日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages">
                <el-pagination @size-change="updatesize" @current-change="update" :current-page="Page+1" :page-sizes="[10,20,30,40,50,60]" :page-size="Limit" layout="total, sizes, prev, pager, next, jumper" :total="Count"></el-pagination>
                <!-- <el-pagination :disabled="isload" background layout="prev, pager, next" :current-page="Page+1" :page-size="Limit" :total="Count" @current-change="update"></el-pagination> -->
            </div>
        </div>
        <el-dialog title="客户日志查询" v-model="dialog_log" :width="1200" :destroy-on-close="true">
            <log ref="renewroom" :dnCustomerID="dnCustomerID" @update="edittrue" @close="dialog_log=false"></log>
        </el-dialog>
    </div>
</template>

<script>
import $webapi from "../../utils/webapi";
import log from "./log/customer"
export default {
    name:"room",
    components:{log},
    created(){
        this.bindData();
    },
    data(){
        return{
            dcCustomerName:"",
            dcRoomNumber:"",
            Page:0,
            Limit:10,
            Count:0,
            isload:false,
            windowHeight: document.documentElement.clientHeight,
            tableData: [],
            dialog_log:false,
            dnCustomerID:0
        }
    },
    methods:{
        GoSearch(){
            this.Page = 0;
            this.bindData();
        },
        ReSearch(){
            this.Page = 0;
            this.dcCustomerName = "";
            this.dcRoomNumber = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getCustomer(this.dcCustomerName,this.dcRoomNumber,this.Page,this.Limit).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data.list;
                    _this.Count = ret.data.count;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
        update(page){
            this.Page = page-1;
            this.bindData();
        },
        updatesize(Limit){
            this.Limit = Limit;
            this.bindData();
        },
        ShowLog(item){
            this.dnCustomerID = item.dnCustomerID;
            this.dialog_log = true;
        },
        renderHeader(h,para) {
            //该列的绑定数据
        },
    },
    computed:{
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
            
        };
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;

        }
        .form{
            padding: 30px;
            padding-top: 0;
        }
    }
</style>