<template>
  <div class="home">
    <el-container class="main">
        <el-header class="el-header"><indexheader></indexheader></el-header>
        <el-main class="el-main">
            <router-view />
        </el-main>
    </el-container>
  </div>
</template>

<script>
import indexheader from "../../components/Header"
export default {
  name: 'index',
  components:{
      indexheader
  }
}
</script>

<style lang="scss" scoped>
    .home{
        height: 100vh;
    }
    .main{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .el-main{
        width: 100%;
        height: calc(100% - 60px);
        overflow: hidden;
        padding: 0;
    }
</style>