<template>
  	<div class="form">
    	<el-form label-width="auto" ref="form" :model="form" :rules="rules" status-icon validate-on-rule-change>
      		<el-row :gutter="20">
        		<el-col :span="18">
          			<el-form-item label="新房间" prop="dnRoomIndex">
            			<el-select v-model="form.dnRoomIndex" placeholder="请选择房间" @change="changeRoom">
              				<el-option v-for="(item,index) in roomList" :key="item.dnRoomID" :label="item.dcRoomNumber+' - '+item.dcRoomName" :value="index">
                				<div class="fd-r"><div class="room-fc-state-1" style="width:110px;">{{item.dcRoomNumber}}-{{item.dcRoomName}}</div>房价:{{item.dnPrice}}元&nbsp;&nbsp;押金:{{item.dnDepositPrice}}元&nbsp;&nbsp;钟点房价:{{item.dnTimePrice}}元</div>
              				</el-option>
            			</el-select>
          			</el-form-item>
        		</el-col>
      		</el-row>
			  <el-collapse-transition>
			<div v-show="form.dnRoomID>0">
				<el-descriptions :column="6" border>
					<el-descriptions-item label="原房间" :span="3">{{old.dcRoomNumber}} - {{old.dcRoomName}}</el-descriptions-item>
					<el-descriptions-item label="新房间" :span="3">{{newroom.dcRoomNumber}} - {{newroom.dcRoomName}}</el-descriptions-item>
					<el-descriptions-item label="原房间价格" :span="3">{{old.dnPrice}}元</el-descriptions-item>
					<el-descriptions-item label="新房间价格" :span="3">{{newroom.dnPrice}}元</el-descriptions-item>
					<el-descriptions-item label="原房间钟点房价" :span="3">{{old.dnTimePrice}}元</el-descriptions-item>
					<el-descriptions-item label="新房间钟点房价" :span="3">{{newroom.dnTimePrice}}元</el-descriptions-item>
					<el-descriptions-item label="原房间押金" :span="3">{{old.dnDepositPrice}}元</el-descriptions-item>
					<el-descriptions-item label="新房间押金" :span="3">{{newroom.dnDepositPrice}}元</el-descriptions-item>
					<el-descriptions-item label="入住天数" :span="3">{{old.dnDay}}天</el-descriptions-item>
					<el-descriptions-item label="实际入住天数" :span="3">{{old.dnDayTrue}}天</el-descriptions-item>
					<el-descriptions-item label="入住时间" :span="3">{{old.dtInTime}}</el-descriptions-item>
					<el-descriptions-item label="预离时间" :span="3">{{old.dtOutTime}}</el-descriptions-item>
				</el-descriptions>
				<div style="height:20px;"></div>
				<el-row :gutter="20">
					<el-col :span="7">
						<el-form-item label="房价" prop="dnPrice">
							<el-input style="width:130px;" v-model.number="form.dnPrice">
								<template #append>
									<div>元</div>
								</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="7">
						<el-form-item label="押金" prop="dnDepositPrice">
						<el-input style="width:130px;" v-model.number="form.dnDepositPrice">
							<template #append>
								<div>元</div>
							</template>
						</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-descriptions :column="4" border v-show="backPrice != 0 || backDepositPrice != 0">
					<el-descriptions-item label="应退房费" :span="2" v-if="backPrice < 0">{{Math.abs(backPrice)}}元</el-descriptions-item>
					<el-descriptions-item label="应补房费" :span="2" v-if="backPrice > 0">{{backPrice}}元</el-descriptions-item>
					<el-descriptions-item label="应退押金" :span="2" v-if="backDepositPrice < 0">{{Math.abs(backDepositPrice)}}元</el-descriptions-item>
					<el-descriptions-item label="应补押金" :span="2" v-if="backDepositPrice > 0">{{backDepositPrice}}元</el-descriptions-item>
				</el-descriptions>
				<div class="fd-r kaiguan">
					<div class="title">是否净房</div><el-switch v-model="form.dnIsClear" active-color="#13ce66"></el-switch>
				</div>
				<div class="fd-r kaiguan">
					<div class="title">是否写卡</div><el-switch v-model="form.dnIsCard" active-color="#13ce66"></el-switch>
				</div>
			</div>
			  </el-collapse-transition>
    	</el-form>
  	</div>
</template>

<script>
import $webapi from "../utils/webapi";
import $utils from "../utils/utils"
export default {
  	created() {
		this.form.dnRoomIndex = "";
		this.form.dnRoomID = 0;
		this.form.dnPrice = 0;
		this.form.dnDepositPrice = 0;
    	this.form.dnIsClear = false;
    	this.form.dnIsCard = true;
    	let _this = this;
    	$webapi.getEmptyRoom().then((ret) => {
			if (ret.success) {
				_this.roomList = ret.data;
			}
    	});
  	},
  	data() {
    	return {
			backPrice:0,
			backDepositPrice:0,
			old:{
				dcRoomNumber:"",
				dcRoomName:"",
				dnPrice:0,
				dnTimePrice:0,
        		dnDepositPrice: 0,
				dnDay:0,
				dtInTime:"",
				dtOutTime:"",
				dnDayTrue:0
			},
			newroom:{
				dcRoomNumber:"",
				dcRoomName:"",
				dnPrice:0,
				dnTimePrice:0,
				dnDepositPrice: 0,
				dnDay:0,
			},
			form: {
				dnRoomIndex:"",
				dnCustomerID: 0,
				dnRoomID: 0,
				dnPrice: 0,
				dnDepositPrice: 0,
				dnIsCard: true,
				dnIsClear:false
			},
			rules: {
				dnRoomIndex: [{ required: true, message: "请选择新房间" }],
				dnPrice: [{ required: true, message: "请填写房价" },{ type: "number", message: "房价必须为数字" }],
				dnDepositPrice: [{ required: true, message: "请填写押金" },{ type: "number", message: "押金必须为数字" }]
			},
			roomList: [],
		};
  	},
  	methods:{
    	changeRoom() {
				let _this = this;
				if(this.form.dnRoomIndex >= 0){
					let selRoom = this.roomList[this.form.dnRoomIndex];
					_this.form.dnRoomID = selRoom.dnRoomID;
					_this.form.dnPrice = selRoom.dnPrice;
					_this.form.dnDepositPrice = selRoom.dnDepositPrice;

					_this.newroom.dcRoomNumber = selRoom.dcRoomNumber;
					_this.newroom.dcRoomName = selRoom.dcRoomName;
					_this.newroom.dnPrice = selRoom.dnPrice;
					_this.newroom.dnTimePrice = selRoom.dnTimePrice;
					_this.newroom.dnDepositPrice = selRoom.dnDepositPrice;
					_this.newroom.dnDay = selRoom.dnDay;
					this.newroom.dnLockType = selRoom.dnLockType;
					this.newroom.dcRoomCode = selRoom.dcRoomCode;
					

					_this.backPrice = Number(Number(_this.old.dnDay)-Number(_this.old.dnDayTrue)+1)*(Number(_this.form.dnPrice)-Number(_this.old.dnPrice));
					_this.backDepositPrice = _this.form.dnDepositPrice-_this.old.dnDepositPrice;
				}
    	},
			writeAnlock() {
				try{
					let card = window.callClient.setWriteCard(1,this.newroom.dcRoomNumber[0],this.newroom.dcRoomNumber,$utils.getDateFCWithDate(this.old.dtOutTime)+" 13:00:00",true);
					if(card != "1" && card != 1){
						this.$emit('SubmitFalse');
						this.$message.error("更换房间失败，写卡失败");
						return false;
					}
				}
				catch{
					this.$emit('SubmitFalse');
					this.$message.error("客户端外不支持写卡");
					return false;
				}
				return true
			},
			writeCardOria() {
				try {
						const startTime = $utils.getCardOriaTime(new Date());
						let dtOutTime = new Date(this.old.dtOutTime.replace(/-/g, "/"));
						dtOutTime = new Date(`${$utils.dateFormat("YYYY-mm-dd", dtOutTime)} 13:00:00`)
						const endTime = $utils.getCardOriaTime(dtOutTime);
						
						let res = window.cardOria.guestCard(
								startTime,
								endTime,
								this.newroom.dcRoomCode
						);
						if(Number(res) != 0){
								this.isSubmit = false;
								this.$message.error("更换房间失败，写卡失败");
								return false
						}
				}
				catch(err){
						this.isSubmit = false;
						this.$message.error("客户端外不支持制卡");
						return false
				}
				return true
			},
			save() {
				let _this = this;
					this.$refs["form"].validate(volid =>{
						if(volid){
							_this.$emit('SubmitTrue');
							if(_this.form.dnIsCard){
								let isTrue = false
								console.log(_this.newroom.dnLockType)
								switch(_this.newroom.dnLockType) {
									case 1:
										if(_this.writeAnlock()){
											isTrue = true
										}
										break;
									case 2:
										if(_this.writeCardOria()){
											isTrue = true
										}
										break;
								}
								if(!isTrue){
									return
								}
							}
							var data = JSON.parse(JSON.stringify(_this.form));
							data.dnIsClear = data.dnIsClear ? 1 : 0;
							$webapi.setEditRoom(data.dnCustomerID,data.dnRoomID,data.dnPrice,data.dnDepositPrice,data.dnIsClear).then(ret=>{
								if(ret.success){
									_this.$message.success(ret.message);
									_this.$emit('update');
								}
								else{
									_this.$emit('SubmitFalse');
									_this.$message.error("更换房间失败，"+ret.message);
								}
							}).catch(err=>{
								_this.$emit('SubmitFalse');
								_this.$message.error("更换房间失败，请重试");
							})
						}
						else{
								return false;
						}
					})
				}
  	},
  	props: {
        _old:Object,
        _form:Object
    },
    watch: {
        _old: { immediate: true, 
            handler (val) {
                this.old = val 
            }
        },
        _form: { immediate: true, 
            handler (val) {
                this.form = val
            }
        },
		"form.dnPrice":{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
      			let _this = this;
                if(Number(_this.old.dnDayTrue) > Number(_this.old.dnDay)){
					_this.backPrice = Number(Number(_this.old.dnDay)-Number(_this.old.dnDayTrue))*(Number(_this.form.dnPrice)-Number(_this.old.dnPrice));
				}
				else{
					_this.backPrice = Number(Number(_this.old.dnDay)-Number(_this.old.dnDayTrue)+1)*(Number(_this.form.dnPrice)-Number(_this.old.dnPrice));
				}
            },
        },
		"form.dnDepositPrice":{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
      			let _this = this;
				_this.backDepositPrice = _this.form.dnDepositPrice-_this.old.dnDepositPrice;
            },
        }
    },
};
</script>

<style lang="scss" scoped>
    .kaiguan{
        padding: 20px 0 0 5px;
        .title{
            margin-right: 10px;
        }
    }
</style>