<template>
    <div class="header">
        <div class="box">
            <div class="left">
                <div class="logo">
                    <svg t="1619512276017" class="icon" viewBox="0 0 1174 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="17923" width="100%" height="100%"><path d="M1134.809821 905.06972h-80.878543V275.834654a39.630486 39.630486 0 0 0-38.821701-39.630486H743.357672V39.669308A38.821701 38.821701 0 0 0 704.535971 0.038822H158.605805a37.20413 37.20413 0 0 0-38.821701 39.630486v865.400412h-80.878543a37.20413 37.20413 0 0 0-38.821701 39.630486v39.630486a37.20413 37.20413 0 0 0 38.821701 39.630486H1134.809821a37.20413 37.20413 0 0 0 38.821701-39.630486v-39.630486a37.20413 37.20413 0 0 0-38.821701-39.630486zM782.179373 472.369514h117.273887V590.452187H782.179373z m0 196.53486h117.273887v118.082673H782.179373zM469.988196 275.834654h117.273888v118.082673H469.988196V275.834654z m0 196.53486h117.273888V590.452187H469.988196z m0 196.53486h117.273888v118.082673H469.988196zM275.070907 275.834654h117.273888v118.082673H275.070907V275.834654z m0 196.53486h117.273888V590.452187H275.070907z m0 196.53486h117.273888v118.082673H275.070907z" p-id="17924" fill="#FFFFFF"></path></svg>
                </div>
                <div class="title">鑫源泰客房管理系统</div>
            </div>
            <div class="item">
                <el-menu
                    :default-active="activeIndex2"
                    :router="true"
                    class="el-menu-demo"
                    mode="horizontal"
                    background-color="#545c64"
                    text-color="#fff"
                    active-text-color="#ffd04b">
                        <el-menu-item index="/index" route="/index">首页</el-menu-item>
                        <el-menu-item index="/card" route="/card">房卡助手</el-menu-item>
                        <el-menu-item index="/count" route="/count" v-if="dcPower.indexOf('统计报表') > -1">统计报表</el-menu-item>
                        <el-menu-item index="/admin" route="/admin" v-if="dcPower.indexOf('房间管理') > -1 || dcPower.indexOf('日志管理') > -1 || dcPower.indexOf('员工管理') > -1">管理维护</el-menu-item>
                </el-menu>
            </div>
        </div>
        <div class="right">
            <el-dropdown>
                <div class="right">
                    <el-avatar icon="el-icon-user-solid"></el-avatar>
                    <div class="user">
                        <div class="name">{{dcName}} - {{dcUserType}}</div>
                        <!-- <div class="type">{{dcUserType}}</div> -->
                    </div>
                </div>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item icon="el-icon-lock" @click="EditPwd">修改密码</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-switch-button" @click="Logout">退出登录</el-dropdown-item>
                        <el-dropdown-item icon="el-icon-switch-button" v-if="dcName == '高孝策'" @click="showDev">开发者工具</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
    <el-dialog title="修改密码" v-model="dialog_editpwd" :width="400">
        <div class="pwd-box">
            <el-input class="input" type="password" placeholder="请输入旧密码" v-model="editPwdForm.oldPwd">
                <template #prepend>旧密码</template>
            </el-input>
            <el-input class="input" type="password" placeholder="请输入新密码" v-model="editPwdForm.newPwd">
                <template #prepend>新密码</template>
            </el-input>
            <el-input class="input" type="password" placeholder="请再次输入新密码" v-model="editPwdForm.newPwdTrue">
                <template #prepend>新密码</template>
            </el-input>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialog_editpwd = false">取 消</el-button>
                <el-button type="primary" @click="EditPwdTrue">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import $webapi from '../utils/webapi';
export default {
    name:"header",
    created(){
        if(this.$route.path.indexOf("/admin") > -1){
            this.activeIndex2 = "/admin";
        }
        else if(this.$route.path.indexOf("/card") > -1){
            this.activeIndex2 = "/card";
        }
        else if(this.$route.path.indexOf("/index") > -1){
            this.activeIndex2 = "/index";
        }
        else if(this.$route.path.indexOf("/count") > -1){
            this.activeIndex2 = "/count";
        }
        this.dcPower = window.localStorage.getItem("dcPower");
        this.dcName = window.localStorage.getItem("dcName");
        this.dcUserType = window.localStorage.getItem("dcUserType");

    },
    data() {
        return {
            activeIndex2: '1',
            dcPower : "",
            dcName:"",
            dcUserType:"",
            dialog_editpwd:false,
            editPwdForm:{
                oldPwd:"",
                newPwd:"",
                newPwdTrue:""
            }
        };
    },
    methods:{
        Logout(){
            window.localStorage.removeItem("dcToken");
            window.location.href = "#/"
        },
        showDev(){
            try{
                window.callClient.showDev();
            }
            catch{
                this.$message.error("客户端外不支持该操作");
            }
        },
        EditPwd(){
            this.editPwdForm.oldPwd = "";
            this.editPwdForm.newPwd = "";
            this.editPwdForm.newPwdTrue = "";
            this.dialog_editpwd = true;
        },
        EditPwdTrue(){
            let _this = this;
            if(this.editPwdForm.oldPwd == ""){
                this.$message.error("请输入旧密码");return;
            }
            if(this.editPwdForm.newPwd == ""){
                this.$message.error("请输入新密码");return;
            }
            if(this.editPwdForm.newPwdTrue == ""){
                this.$message.error("请再次输入新密码");return;
            }
            if(this.editPwdForm.newPwd != this.editPwdForm.newPwdTrue){
                this.$message.error("输入的新密码不一致");return;
            }
            if(this.editPwdForm.oldPwd == this.editPwdForm.newPwd){
                this.$message.error("新密码不能与旧密码相同");return;
            }
            $webapi.setEditPassword(_this.editPwdForm.oldPwd,_this.editPwdForm.newPwd).then(ret=>{
                if(ret.success){
                    _this.$message.success("密码修改成功");
                    _this.dialog_editpwd = false;
                }
                else{
                    _this.$message.error(ret.message);
                }
            }).catch(err=>{
                _this.$message.error("密码修改失败，请重试");
            });
        }
    }
}
</script>

<style lang="scss">
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #545c64;
        color: #fff;
        height: 60px;
        align-items: center;
        padding: 0 30px;
        .box{
            display: flex;
            flex-direction: row;
            align-items: center;
            .left{
                display: flex;
                flex-direction: row;
                align-items: center;
                .logo{
                    height: 30px;
                    width: 30px;
                }
                .title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 20px;
                    margin-right: 50px;
                    padding-left: 10px;
                }
            }
        }
        .right{
            color: #eee;
            display: flex;
            flex-direction: row;
            align-items: center;
            .user{
                padding-left: 10px;
                .name{
                    font-size: 16px;
                }
                .type{
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }
    }
    .pwd-box{
        padding: 0 10px;
        .input{
            margin: 3px 0;
        }
    }
</style>