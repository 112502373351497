<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">查询日期</div>
                <el-date-picker v-model="date" :editable="false" align="right" type="date" placeholder="选择日期" style="width:165px;" :shortcuts="shortcuts" :default-value="new Date()" :clearable="false" :picker-options="pickerOptions"> </el-date-picker>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
        </div>

        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="mini" :max-height="windowHeight-250" v-loading="isload" >
                <el-table-column align="center" prop="user.dcName" label="操作人" width="120"></el-table-column>
                <el-table-column align="center" prop="customer.dcCustomerName" label="客户姓名" width="120"></el-table-column>
                <el-table-column align="center" prop="customer.dtInTime" label="入住时间" width="150"></el-table-column>

                <el-table-column align="center" label="原房间" width="120">
                    <template #default="scope">
                       {{scope.row.dcContent.split(',')[0].replace(/\从/g,"").replace(/\【/g,"").replace(/\】/g,"").split('更换到')[0].split('-')[0]}}
                       -
                       {{scope.row.dcContent.split(',')[0].replace(/\从/g,"").replace(/\【/g,"").replace(/\】/g,"").split('更换到')[0].split('-')[1]}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="新房间" width="120">
                    <template #default="scope">
                       {{scope.row.dcContent.split(',')[0].replace(/\从/g,"").replace(/\【/g,"").replace(/\】/g,"").split('更换到')[1].split('-')[0]}}
                       -
                       {{scope.row.dcContent.split(',')[0].replace(/\从/g,"").replace(/\【/g,"").replace(/\】/g,"").split('更换到')[1].split('-')[1]}}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="原房间价格" width="100">
                    <template #default="scope">
                       {{scope.row.dcContent.split(',')[1].split('元变更为')[0].replace('房价从','')}}元
                    </template>
                </el-table-column>
                <el-table-column align="center" label="新房间价格" width="100">
                    <template #default="scope">
                       {{scope.row.dcContent.split(',')[1].split('元变更为')[1].replace('元。','')}}元
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="dtAddTime" label="变更时间" width="150"></el-table-column>
            </el-table>
            <div class="pages">
                <el-pagination @size-change="updatesize" @current-change="update" :current-page="Page+1" :page-sizes="[10,20,30,40,50,60]" :page-size="Limit" layout="total, sizes, prev, pager, next, jumper" :total="Count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import $utils from '../../utils/utils';
import $webapi from "../../utils/webapi";
export default {
    name:"room",
    created(){
        this.bindData();
    },
    data(){
        return{
            date:"",
            Page:0,
            Limit:10,
            Count:0,
            isload:false,
            windowHeight: document.documentElement.clientHeight,
            tableData: [],
        }
    },
    methods:{
        GoSearch(){
            this.Page = 0;
            this.bindData();
        },
        ReSearch(){
            this.Page = 0;
            this.date = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getEditRoomCount((this.date == "" ? "" : $utils.getDateFCWithDate(this.date)),this.Page,this.Limit).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data.list;
                    _this.Count = ret.data.count;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
        update(page){
            this.Page = page-1;
            this.bindData();
        },
        updatesize(Limit){
            this.Limit = Limit;
            this.bindData();
        },
        renderHeader(h,para) {
            //该列的绑定数据
        },
    },
    computed:{
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => { 
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
        };
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;

        }
        .form{
            padding: 30px;
            padding-top: 0;
        }
    }
</style>