<template>
  	<div class="form">
    	<el-form label-width="auto" ref="form" :model="form" :rules="rules" status-icon validate-on-rule-change>
            <el-descriptions :column="6" border>
                <el-descriptions-item label="房间" :span="3">{{form.room.dcRoomNumber}} - {{form.room.dcRoomName}}</el-descriptions-item>
                <el-descriptions-item label="入住人" :span="3">{{form.customer.dcCustomerName}}</el-descriptions-item>
                <el-descriptions-item label="房间价格" :span="3">{{form.room.dnPrice}}元</el-descriptions-item>
                <el-descriptions-item label="入住价格" :span="3">{{form.customer.dnPrice}}元</el-descriptions-item>
                <el-descriptions-item label="房间押金" :span="3">{{form.room.dnDepositPrice}}元</el-descriptions-item>
                <el-descriptions-item label="实收押金" :span="3">{{form.customer.dnDepositPrice}}元</el-descriptions-item>
                <el-descriptions-item label="入住时间" :span="3">{{form.customer.dtInTime}}</el-descriptions-item>
                <el-descriptions-item label="预离时间" :span="3">{{form.customer.dtOutTime}}</el-descriptions-item>
            </el-descriptions>
            <div style="height:20px;"></div>
            <el-row :gutter="20">
                <el-col :span="10">
                    <el-form-item label="续费天数" prop="data.dnDay">
                        <el-input-number v-model.number="form.data.dnDay"  :min="1" :max="3650" label="描述文字" style="width:165px;"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="预离日期" prop="data.dtOutTime">
                        <el-date-picker v-model="form.data.dtOutTime" :editable="false" align="right" type="date" placeholder="选择日期" style="width:165px;" :clearable="false" :picker-options="pickerOptions"> </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="9">
                    <el-form-item label="续费房价" prop="data.dnPrice">
                        <el-input style="width:130px;" v-model.number="form.data.dnPrice">
                            <template #append>
                                <div>元</div>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="应补缴房费:">
                        <div class="fs-20 room-fc-state-1 fw-b">{{(form.data.dnDay*form.data.dnPrice)}}元</div> 
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="fd-r kaiguan">
                <div class="title">是否写卡</div><el-switch v-model="form.data.dnIsCard" active-color="#13ce66"></el-switch>
            </div>
    	</el-form>
  	</div>
</template>

<script>
import $webapi from "../utils/webapi";
import $utils from "../utils/utils"
export default {
  	data() {
    	return {
            isUpdate:true,
            form:{
                room:{
                    dcRoomNumber:"",
                    dcRoomName:"",
                    dnPrice:0,
                    dnDepositPrice:0,
                },
                customer:{
                    dcCustomerName:"",
                    dnPrice:0,
                    dnDepositPrice:0,
                    dtInTime:"",
                    dtOutTime:"",
                },
                data:{
                    dnCustomerID:0,
                    dnPrice:0,
                    dnDay:1,
                    dtOutTime:"",
                    dnIsCard:true
                }
            },
            rules:{
                "data.dnDay":[{ required: true, message: '请选择续费天数'},{ type: 'number', message: '天数必须为数字'}],
                "data.dnPrice":[{ required: true, message: '请填写房价'},{ type: 'number', message: '房价必须为数字'}],
                "data.dtOutTime":[{ required: true, message: '请选择预离日期'}],
            },
		};
  	},
  	methods:{
        writeAnlock() {
            try{
                let card = window.callClient.setWriteCard(
                    1,
                    this.form.room.dcRoomNumber[0],
                    this.form.room.dcRoomNumber,
                    $utils.getDateFCWithDate(this.form.data.dtOutTime)+" 13:00:00",
                    true
                );
                if(card != "1" && card != 1){
                    this.$emit('SubmitFalse');
                    this.$message.error("房间续费失败，写卡失败");
                    return false;
                }
            }
            catch{
                this.$emit('SubmitFalse');
                this.$message.error("客户端外不支持写卡");
                return false;
            }
            return true
        },
        writeCardOria() {
            try {
                const startTime = $utils.getCardOriaTime(new Date());
                let dtOutTime = this.form.data.dtOutTime;
                dtOutTime = new Date(`${$utils.dateFormat("YYYY-mm-dd", dtOutTime)} 13:00:00`)
                const endTime = $utils.getCardOriaTime(dtOutTime);
                let res = window.cardOria.guestCard(
                    startTime,
                    endTime,
                    this.form.data.dcRoomCode
                );
                if(Number(res) != 0){
                    this.isSubmit = false;
                    this.$message.error("房间续费失败，写卡失败");
                    return false
                }
            }
            catch(err){
                this.isSubmit = false;
                this.$message.error("客户端外不支持制卡");
                return false
            }
            return true
        },
		save(){
			let _this = this;
            this.$refs["form"].validate(volid =>{
                if(volid){
                    _this.$emit('SubmitTrue');
					if(_this.form.data.dnIsCard){
                        let isTrue = false
                        switch(_this.form.data.dnLockType) {
                            case 1:
                                if(_this.writeAnlock()){
                                    isTrue = true
                                }
                                break;
                            case 2:
                                if(_this.writeCardOria()){
                                    isTrue = true
                                }
                                break;
                        }
                        if(!isTrue){
                            return
                        }
                    }
					$webapi.setRenewRoom(_this.form.data.dnCustomerID,_this.form.data.dnDay,_this.form.data.dnPrice).then(ret=>{
						if(ret.success){
							_this.$message.success(ret.message);
							_this.$emit('update');
						}
						else{
                            _this.$emit('SubmitFalse');
							_this.$message.error("房间续费失败，"+ret.message);
						}
					}).catch(err=>{
                        _this.$emit('SubmitFalse');
						_this.$message.error("房间续费失败，请重试");
					})
                }
                else{
                    return false;
                }
            })
		}
  	},
    props: {
        _form:Object
    },
    watch: {
        _form: { immediate: true, 
            handler (val) {
                this.form = val 
            }
        },
        'form.data.dnDay':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                if(this.isUpdate){
                    var dateTime = new Date($utils.getDateFC(this.form.customer.dtOutTime));/**获取旧的预离日期 */
                    dateTime = new Date(dateTime.setDate(dateTime.getDate()+newName));/**预离日期（当前时间+入住天数-当日过早时间） */
                    this.isUpdate = false;
                    this.form.data.dtOutTime = dateTime;
                }else{
                    this.isUpdate = true;
                }
            },
        },
        'form.data.dtOutTime':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                if(this.isUpdate){
                    var dateTime=new Date($utils.getDateFC(this.form.customer.dtOutTime));
                    var day = Math.floor(Math.abs(newName - dateTime) / 1000 / 60 / 60 /24);
                    this.isUpdate = false;
                    this.form.data.dnDay = day;
                }else{
                    this.isUpdate = true;
                }
            },
        }
    }
};
</script>

<style lang="scss" scoped>
    .kaiguan{
        padding: 20px 0 0 5px;
        .title{
            margin-right: 10px;
        }
    }
</style>