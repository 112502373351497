<template>
  <div class="form">
    <el-form label-width="auto" :model="roomform" :rules="roomrules" status-icon validate-on-rule-change ref="roomform">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-form-item label="门锁品牌" prop="dnLockType">
                    <el-select v-model="roomform.dnLockType" placeholder="请选择">
                        <el-option v-for="item in $store.state.lockTypeList" :key="`locktype_${item.key}`" :label="item.value" :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="房间代码" prop="dcRoomCode" v-if="roomform.dnLockType == 2">
                    <el-input v-model="roomform.dcRoomCode" placeholder="请输入房间代码"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="楼栋号" prop="dnBulidingNumber">
                    <el-input v-model.number="roomform.dnBulidingNumber" placeholder="请填写楼栋号"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="楼层号" prop="dnFloorNumber">
                    <el-input v-model.number="roomform.dnFloorNumber" placeholder="请填写房间号"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="房间号" prop="dcRoomNumber">
                    <el-input v-model="roomform.dcRoomNumber" placeholder="请填写房间号"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="房型" prop="dcRoomName">
                    <el-select v-model="roomform.dcRoomName" placeholder="请选择" value="前台">
                        <el-option v-for="item in $store.state.roomTypeList" :key="item.value" :label="item.value" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="房价" prop="dnPrice">
                    <el-input v-model.number="roomform.dnPrice" placeholder="房价"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="钟点房价" prop="dnTimePrice">
                    <el-input v-model.number="roomform.dnTimePrice" placeholder="钟点房价"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="默认押金" prop="dnDepositPrice">
                    <el-input v-model.number="roomform.dnDepositPrice" placeholder="默认押金"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="是否启用">
                    <el-switch v-model="roomform.dnIsShow" active-color="#13ce66"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-button type="primary" @click="submitSave" style="width:100px;">保存</el-button>
                <el-button type="info" plain @click="handleClose" style="width:100px;">取消</el-button>
            </el-col>
        </el-row>
    </el-form>
</div>
</template>

<script>
import $webapi from "../utils/webapi"
export default {
    data(){
        return {
            roomform:{
                dnLockType: 1,
                dcRoomCode: "",
                dnRoomID:0,
                dnBulidingNumber:1,
                dnFloorNumber:"",
                dcRoomNumber:"",
                dcRoomName:"",
                dnPrice:"",
                dnTimePrice:"",
                dnDepositPrice:"",
                dnIsShow:true
            },
            roomrules:{
                dnBulidingNumber:[{ required: true, message: '楼栋号不能为空'},{ type: 'number', message: '楼栋号必须为数字'}],
                dnFloorNumber:[{ required: true, message: '楼层不能为空'},{ type: 'number', message: '楼层必须为数字'}],
                dcRoomNumber:[{ required: true, message: '房间不能为空'}],
                dcRoomName:[{ required: true, message: '请选择房型'}],
                dnPrice:[{ required: true, message: '房价不能为空'},{ type: 'number', message: '房价必须为数字'}],
                dnTimePrice:[{ required: true, message: '请填写钟点房价'},{ type: 'number', message: '房价必须为数字'}],
                dnDepositPrice:[{ required: true, message: '默认押金不能为空'},{ type: 'number', message: '押金必须为数字'}],
            },
        }
    },
    props: {
        _roomform:Object
    },
    watch: {
        _roomform: { immediate: true, 
            handler (val) {
                this.roomform = val 
            }
        }
    },
    methods:{
        submitSave(){
            let _this = this;
            this.$refs["roomform"].validate(volid =>{
                if(volid){
                    var data = JSON.parse(JSON.stringify(_this.roomform));
                    data.dnIsShow = data.dnIsShow ? 1:0;
                    data.dcRoomNumber = data.dcRoomNumber.toString();
                    if(_this.roomform.dnRoomID == 0){
                        $webapi.setAddAdminRoomList(data).then(ret=>{
                            if(ret.success){
                                _this.$message.success(ret.message);
                                this.$emit('update');
                            }
                            else{
                                _this.$message.error(ret.message);
                            }
                        }).catch(err=>{
                            _this.$message.error("添加房间失败，请重试");
                        });
                    }
                    else{
                        $webapi.setUpdateAdminRoomList(data).then(ret=>{
                            if(ret.success){
                                _this.$message.success(ret.message);
                                this.$emit('update');
                            }
                            else{
                                _this.$message.error(ret.message);
                            }
                        }).catch(err=>{
                            _this.$message.error("修改房间失败，请重试");
                        });
                    }
                }
                else{
                    return false;
                }
            })
        },
        handleClose(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    .form{
        padding: 30px;
        padding-top: 0;
    }
</style>