<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'zh')
  }
  // other options
}
</script>

<style>
@import url('./assets/css/reset.css');
*{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
button{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
input{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
.el-header{
  padding:0;
}
#app{
  height: 100vh;
  width: 100vw;
}
.flex1{
  display: flex;
  flex: 1;
}
.fd-r{
  display: flex;
  flex-direction: row;
}
.room-state-1{
    background: #1e90ff;
}
.room-state-2{
    background: #ff8000;
}
.room-state-3{
    background: #ff58c7;
}
.room-state-4{
    background: #002060;
}
.room-state-5{
    background: #006000;
}
.room-state-6{
    background: #FF2000;
}
.room-fc-state-1{
    color: #1e90ff;
}
.room-fc-state-2{
    color: #ff8000;
}
.room-fc-state-3{
    color: #ff58c7;
}
.room-fc-state-4{
    color: #002060;
}
.room-fc-state-5{
    color: #006000;
}
.room-fc-state-6{
    color: #FF2000;
}
.fc-success{
  color: #67C23A;
}
.fc-error{
  color: #F56C6C;
}
.fs-10{
  font-size: 10px !important;
}
.fs-12{
  font-size: 12px !important;
}
.fs-14{
  font-size: 14px !important;
}
.fs-16{
  font-size: 16px !important;
}
.fs-18{
  font-size: 18px !important;
}
.fs-20{
  font-size: 20px !important;
}
.fw-b{
  font-weight: bold;
}

</style>
