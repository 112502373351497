<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">搜索</div>
                <el-input v-model="Search" placeholder="姓名/用户名" style="width:200px;" :clearable="true" size="medium"></el-input>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
            <div class="flex1"></div>
            <el-button type="primary" size="medium" icon="el-icon-plus" @click="Add">添加员工</el-button>
        </div>

        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="mini" :max-height="windowHeight-250" v-loading="isload" >
                <el-table-column fixed align="center" prop="dcName" label="姓名" width="100"></el-table-column>
                <el-table-column align="center" prop="dcUserName" label="用户名" width="140"></el-table-column>
                <el-table-column align="center" prop="dcUserType" label="身份" width="120"></el-table-column>
                <el-table-column align="center" prop="dcPower" label="权限" width="500">
                    <template #default="scope">
                      <div class="fd-r" ><div v-for="item in JSON.parse(scope.row.dcPower)" :key="item">{{item}}</div></div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="dtAddTime" label="添加时间" width="200"></el-table-column>
                <el-table-column align="center" prop="dtEditTime" label="修改时间" width="200"></el-table-column>
                <el-table-column align="center" prop="dnIsShow" label="状态" fixed="right" width="100">
                    <template #default="scope">
                        <div class="fc-success fw-b" v-show="scope.row.dnIsShow == 1" >启用</div>
                        <div class="fc-error fw-b" v-show="scope.row.dnIsShow == 0">停用</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="200">
                    <template #default="scope">
                        <el-button type="text" size="small" @click="Edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" v-show="scope.row.dnIsShow == 1" @click="setShow(scope.row.dnUserID,0)">停用</el-button>
                        <el-button type="text" size="small" v-show="scope.row.dnIsShow == 0" @click="setShow(scope.row.dnUserID,1)">启用</el-button>
                        <el-popconfirm title="确定删除该员工吗？" @confirm="Del(scope.row.dnUserID)">
                            <template #reference>
                                <el-button type="text" size="small">删除</el-button>
                            </template>
                        </el-popconfirm>
                        <el-button type="text" size="small" @click="ShowLog(scope.row)">查看日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages">
                <el-pagination @size-change="updatesize" @current-change="update" :current-page="Page+1" :page-sizes="[10,20,30,40,50,60]" :page-size="Limit" layout="total, sizes, prev, pager, next, jumper" :total="Count"></el-pagination>
            </div>
        </div>
        <el-drawer :title="form.dnUserID>0?'编辑房间':'添加房间'" v-model="Drawer" direction="rtl" destroy-on-close :before-close="handleClose" :size="400">
            <addUserForm :_roomform="form" @update="edittrue" @close="Drawer=false"></addUserForm>
        </el-drawer>
        <el-dialog title="员工日志查询" v-model="dialog_log" :width="1200" :destroy-on-close="true">
            <log :dnUserID="dnUserID" @close="dialog_log=false"></log>
        </el-dialog>
    </div>
</template>

<script>
import addUserForm from "../../components/addUser";
import log from "./log/user";
import $webapi from "../../utils/webapi";
export default {
    name:"room",
    components:{addUserForm,log},
    created(){
        this.bindData();
    },
    data(){
        return{
            Search:"",
            Type:"",
            Page:0,
            Limit:10,
            Count:0,
            isload:false,
            Drawer:false,
            form:{
                dnUserID:0,
                dcUserName:"",
                dcPassWord:"",
                dcName:"",
                dcPower:"",
                dcUserType:"",

            },
            windowHeight: document.documentElement.clientHeight,
            tableData: [],
            dialog_log:false,
            dnUserID:0
        }
    },
    methods:{
        GoSearch(){
            this.Page = 0;
            this.bindData();
        },
        ReSearch(){
            this.Page = 0;
            this.Search = "";
            this.Type = "";
            this.State = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getAdminUserList(this.Search,this.Type,this.Page,this.Limit).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data.list;
                    _this.Count = ret.data.count;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
        setShow(dnUserID,dnIsShow){
            let _this = this;
            $webapi.setAdminUserShow(dnUserID,dnIsShow).then(ret=>{
                if(ret.success){
                    _this.$message.success(ret.message);
                    _this.bindData();
                }
                else{
                    _this.$message.error(ret.message);
                }
            }).catch(err=>{
                _this.$message.error("操作失败，请重试");
            })
        },
        Del(dnUserID){
            let _this = this;
            $webapi.setDelAdminUserList(dnUserID).then(ret=>{
                if(ret.success){
                    _this.$message.success(ret.message);
                    _this.bindData();
                }
                else{
                    _this.$message.error(ret.message);
                }
            }).catch(err=>{
                _this.$message.error("删除失败，请重试");
            })
        },
        update(page){
            this.Page = page-1;
            this.bindData();
        },
        updatesize(Limit){
            this.Limit = Limit;
            this.bindData();
        },
        ShowLog(item){
            this.dnUserID = item.dnUserID;
            this.dialog_log = true;
        },
        renderHeader(h,para) {
            //该列的绑定数据
        },
        edittrue(){
            this.bindData();
            this.Drawer = false;
        },
        Edit(item){
            this.form.dnUserID = item.dnUserID;
            this.form.dcUserName = item.dcUserName;
            this.form.dcPassWord = item.dcPassWord;
            this.form.dcName = item.dcName;
            this.form.dcPower = JSON.parse(item.dcPower);
            this.form.dnIsShow = item.dnIsShow == 1;
            this.form.dcUserType = item.dcUserType;
            this.Drawer = true;
        },
        Add(){
            this.form.dnUserID = 0;
            this.form.dcUserName = "";
            this.form.dcPassWord = "";
            this.form.dcName = "";
            this.form.dcPower = ["房态查看"];
            this.form.dcUserType = "";
            this.form.dnIsShow = true;
            this.Drawer = true;
        }
    },
    computed:{
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
            
        };
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
            .fd-r{
              justify-content: center;
              div{
                padding: 1px 6px;
                margin: 0 3px;
                border: solid 1px #eee;
                border-radius: 5px;
                background: #eee;
                height: 26px;
                line-height: 24px;
              }
            }
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;

        }
        .form{
            padding: 30px;
            padding-top: 0;
        }
    }
</style>