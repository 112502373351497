<template>
  <div class="form">
    <el-form label-width="auto" :model="form" :rules="rules" status-icon validate-on-rule-change ref="form">
        <el-row :gutter="20">
            <el-col :span="24">
                <el-form-item label="姓名" prop="dcName">
                    <el-input v-model="form.dcName" placeholder="请输入员工姓名"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="身份" prop="dcUserType">
                    <el-select v-model="form.dcUserType" placeholder="请选择员工身份" value="前台">
                        <el-option v-for="item in $store.state.userTypeList" :key="item.value" :label="item.value" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="用户名" prop="dcUserName">
                    <el-input v-model="form.dcUserName" placeholder="请输入用户名，用于登录"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="密码" prop="dcPassWord">
                    <el-input v-model="form.dcPassWord" placeholder="请输入密码"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="权限" prop="dcPower">
                    <el-checkbox-group v-model="form.dcPower">
                        <el-checkbox label="房态查看" value="房态查看" disabled></el-checkbox>
                        <el-checkbox label="房态管理" value="房态管理"></el-checkbox>
                        <el-checkbox label="统计报表" value="统计报表"></el-checkbox>
                        <el-checkbox label="房间管理" value="房间管理"></el-checkbox>
                        <el-checkbox label="客户管理" value="客户管理"></el-checkbox>
                        <el-checkbox label="员工管理" value="员工管理"></el-checkbox>
                        <el-checkbox label="日志管理" value="日志管理"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="是否启用">
                    <el-switch v-model="form.dnIsShow" active-color="#13ce66"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-button type="primary" @click="submitSave" style="width:100px;">保存</el-button>
                <el-button type="info" plain @click="handleClose" style="width:100px;">取消</el-button>
            </el-col>
        </el-row>
    </el-form>
</div>
</template>

<script>
import $webapi from "../utils/webapi"
export default {
    data(){
        return {
            form:{
                dnUserID:0,
                dcUserName:"",
                dcPassWord:"",
                dcName:"",
                dcUserType:"",
                dcPower:["房态查看"],
                dnIsShow:false
            },
            rules:{
                dcName:[{ required: true, message: '姓名不能为空'}],
                dcPassWord:[{ required: true, message: '密码不能为空'}],
                dcUserName:[{ required: true, message: '用户名不能为空'}],
                dcUserType:[{ required: true, message: '请选择员工身份'}],
                dcPower:[{ required: true, message: '请选择员工权限'}],
            },
        }
    },
    props: {
        _roomform:Object
    },
    watch: {
        _roomform: { immediate: true, 
            handler (val) {
                this.form = val 
            }
        }
    },
    methods:{
        submitSave(){
            let _this = this;
            this.$refs["form"].validate(volid =>{
                if(volid){
                    var data = JSON.parse(JSON.stringify(_this.form));
                    data.dnIsShow = data.dnIsShow ? 1:0;
                    data.dcPower = JSON.stringify(_this.form.dcPower);
                    if(_this.form.dnUserID == 0){
                        $webapi.setAddAdminUserList(data).then(ret=>{
                            if(ret.success){
                                _this.$message.success(ret.message);
                                this.$emit('update');
                            }
                            else{
                                _this.$message.error(ret.message);
                            }
                        }).catch(err=>{
                            _this.$message.error("添加员工失败，请重试");
                        });
                    }
                    else{
                        $webapi.setUpdateAdminUserList(data).then(ret=>{
                            if(ret.success){
                                _this.$message.success(ret.message);
                                this.$emit('update');
                            }
                            else{
                                _this.$message.error(ret.message);
                            }
                        }).catch(err=>{
                            _this.$message.error("编辑员工信息失败，请重试");
                        });
                    }
                }
                else{
                    return false;
                }
            })
        },
        handleClose(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    .form{
        padding: 30px;
        padding-top: 0;
    }
</style>