<template>
  <div class="login-box">
    <div class="box">
      <div class="left">
        <img class="logo" src="../../assets/image/qiucloud.png" alt="" />
        <div class="flex1"></div>
        <div class="copy">©2021 邱云网络</div>
      </div>
      <div class="main">
        <div class="title">客房管理系统</div>
        <div class="tips">Guestroom Management System</div>
        <div class="item">
          <svg
            t="1619510512991"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2181"
            width="20"
            height="20"
          >
            <path
              d="M835.5 743.9c-1-2.5-2.1-4.9-3.2-7.4-13.3-29.5-50.6-38.9-76.3-19.2l-0.6 0.6c-17 17-21.7 42.6-12.3 64.7 13.1 30.7 20.3 64.4 20.3 99.8 0 1.5-1.2 2.7-2.7 2.7H254.6c-1.5 0-2.7-1.2-2.7-2.7 0-138.8 111.3-250.8 249.3-254.4h4c13.1 0 26-1 38.7-3 4.9-0.7 9.9-1.6 15-2.8 0.3-0.1 0.6-0.1 1-0.2 40.4-9 77.9-28 109.4-55.8 0.2-0.2 0.5-0.4 0.7-0.7 3.8-3.4 7.5-6.9 11.2-10.5 47-47 72.9-109.6 72.9-176.1s-25.9-129-72.9-176.1c-47-47-109.6-72.9-176.1-72.9s-129 25.9-176.1 72.9c-47 47-72.9 109.6-72.9 176.1S282 507.9 329 555c4.2 4.2 8.6 8.3 13.1 12.2-31.7 16.6-60.5 37.9-86.1 63.4-32.7 32.7-58.3 70.7-76.2 113.1-18.6 43.9-28 90.5-28 138.5 0 20.5 1.8 41.1 5.3 61.3 4.2 24 25 41.4 49.3 41.4h602.5c24.3 0 45.1-17.5 49.3-41.4 3.5-20.2 5.3-40.8 5.3-61.3 0-47.8-9.4-94.4-28-138.3z m-326-513.7c78.8 2.3 142.7 66.4 144.6 145.2C656 453 598.2 517.7 523.5 527c-5.3-0.2-10.5-0.4-15.8-0.4-6.5 0-13 0.2-19.4 0.5-74.2-8.4-132.1-71.6-132.1-148-0.1-83.6 69.1-151.4 153.3-148.9z"
              fill="#7d99ef"
              opacity=".8"
              p-id="2182"
            ></path>
            <path
              d="M212.9 929.3m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="2183"
            ></path>
            <path
              d="M416.9 573.9m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="2184"
            ></path>
            <path
              d="M802.5 929.8m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="2185"
            ></path>
          </svg>
          <input
            type="text"
            name=""
            placeholder="请输入用户名"
            v-model="username"
            id=""
            @keyup.enter="login"
          />
        </div>
        <div class="item">
          <svg
            t="1619510369278"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1199"
            width="20"
            height="20"
          >
            <path
              d="M845.9 414.5H362.4c-0.1-2.9-0.1-5.7-0.1-8.6 0-68 21.5-131.4 60.5-178.7 36.7-44.5 84.6-69 135-69s98.3 24.5 135 69c15.9 19.3 28.8 41.2 38.6 65 7.7 18.6 26 30.6 46.1 30.6 35.6 0 59.8-36.2 46.2-69.1-13.6-32.9-31.6-63.3-53.8-90.2-26.9-32.6-58.4-58.4-93.8-76.5-37.4-19.2-77.2-28.9-118.4-28.9s-81 9.7-118.4 28.9c-35.3 18.1-66.9 43.9-93.8 76.5-53.7 65.2-83.3 151.2-83.3 242.3 0 2.9 0 5.7 0.1 8.6h-18.6c-34.2 0-62 27.8-62 62v363.1c0 34.2 27.8 62 62 62h602.2c34.2 0 62-27.8 62-62v-363c0-34.3-27.8-62-62-62z m-68 387.1H311.7c-16.6 0-30-13.4-30-30V544.5c0-16.6 13.4-30 30-30h466.2c16.6 0 30 13.4 30 30v227.1c0 16.6-13.4 30-30 30z"
              fill="#7d99ef"
              opacity=".8"
              p-id="1200"
            ></path>
            <path
              d="M313 449.1m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="1201"
            ></path>
            <path
              d="M846.8 842.5m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="1202"
            ></path>
            <path
              d="M242.5 841.5m-54.9 0a54.9 54.9 0 1 0 109.8 0 54.9 54.9 0 1 0-109.8 0Z"
              fill="#7d99ef"
              opacity=".8"
              p-id="1203"
            ></path>
            <path
              d="M572.2 651.2v-41c0-6.6-5.4-12-12-12h-30.8c-6.6 0-12 5.4-12 12v41c-16.4 9.5-27.5 27.2-27.5 47.6 0 30.3 24.6 54.9 54.9 54.9s54.9-24.6 54.9-54.9c0-20.4-11.1-38.1-27.5-47.6z"
              fill="#7d99ef"
              p-id="1204"
            ></path>
          </svg>
          <input
            type="password"
            name=""
            placeholder="请输入密码"
            v-model="password"
            id=""
            @keyup.enter="login"
          />
        </div>
        <el-button class="login" @click="login" :loading="islogin">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import $webapi from "../../utils/webapi";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      islogin: false,
    };
  },
  methods: {
    login() {
      let _this = this;
      if(this.username == ""){
        _this.$message.error("请输入用户名");return;
      }
      if(this.password == ""){
        _this.$message.error("请输入密码");return;
      }
      _this.islogin = true;
      $webapi
        .getLogin(_this.username, _this.password)
        .then((ret) => {
          if (ret.success) {
            window.localStorage.setItem("dcToken",ret.data.dcToken);
            window.localStorage.setItem("dcPower",ret.data.dcPower);
            window.localStorage.setItem("dcName",ret.data.dcName);
            window.localStorage.setItem("dcUserType",ret.data.dcUserType);
            _this.$router.push("/index");
          } else {
            setTimeout(() => {
              _this.islogin = false;
            }, 300);
            _this.$message.error(ret.message);
          }
        })
        .catch((err) => {
          _this.islogin = false;
        });
    },
  },
};
</script>

<style lang="scss">
.flex1 {
  display: flex;
  flex: 1;
}
.login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 100%;
  width: 100%;
  .box {
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    width: 900px;
    height: 480px;
    .left {
      display: flex;
      flex-direction: column;
      background-image: linear-gradient(145deg, #82fbf2, #6b4ff5);
      height: 100%;
      width: 260px;
      .logo {
        width: 140px;
      }
      .copy {
        padding: 20px;
        color: #ddd;
        font-size: 10px;
      }
    }
    .main {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 30px;
        color: #7d99ef;
      }
      .tips {
        color: #999;
        padding: 5px 0 20px 0;
        font-size: 10px;
      }
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;
        height: 40px;
        width: 240px;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 3px;
        margin-bottom: 10px;
        input {
          display: flex;
          flex: 1;
          height: 100%;
          background: rgba(0, 0, 0, 0);
          border: none;
          margin-left: 10px;
          font-size: 14px;
          color: #666;
        }
      }
      .login {
        height: 40px;
        width: 240px;
        background-image: linear-gradient(135deg, #82a8ee, #6b4ff5);
        border: none;
        border-radius: 20px;
        color: #fff;
        font-size: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 10px;
        transition: background-image 0.3s;
        &:hover {
          background-image: linear-gradient(135deg, #82a8eeee, #6b4ff5ee);
        }
        &:active {
          background-image: linear-gradient(135deg, #82a8eeaa, #6b4ff5aa);
        }
      }
    }
  }
}
</style>