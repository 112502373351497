import axios from "axios";
const $ajax = function(method,url,data){
    const promise = new Promise(function (resolve, reject) {
        let config = {
            method:method,
            withCredentials:false,
            url:url,
            data:data,
            params:data
        };
        axios.request(config).then(ret=>{
            if(ret.data.message == "在其他设备登录"){
                window.localStorage.removeItem("dcToken");
                window.location.href = "#/"
            }
            else{
                resolve(ret.data);
            }
        }).catch(err=>{
            reject(err);
        });
    });
    return promise;
}
const $webapi = {
    /**
     * 用户登录
     * @param {string} UserName 用户名
     * @param {string} PassWord 密码
     * @returns 
     */
    getLogin(UserName,PassWord){
        return $ajax("GET","/api/user/login",{UserName:UserName,PassWord:PassWord});
    },
    /**
     * 修改密码
     * @param {string} oldPwd 旧密码
     * @param {string} newPwd 新密码
     * @returns 
     */
    setEditPassword(oldPwd,newPwd){
        return $ajax("PUT","/api/user/editpwd",{oldPwd:oldPwd,newPwd:newPwd});
    },
    /**
     * 添加开卡日志
     * @param {string} dcContent 开卡日志内容
     * @returns 
     */
    setAddCardLog(dcContent){
        return $ajax("POST","/api/user/cardlog",{dcContent:dcContent});
    },
    /**
     * 获取房间列表
     * @returns 
     */
    getRoomList(){
        return $ajax("GET","/api/room/room",{});
    },
    /**
     * 房客入住
     * @param {*} data 表单
     * @returns 
     */
    setTookRoom(data){
        return $ajax("POST","/api/room/tookroom",data)
    },
    /**
     * 修改房态
     * @param {number} dnRoomID 房间ID
     * @param {number} dnRoomState 房态（1.空房，2.已入住，3.脏房，4.自用，5.预定，6.维修）
     * @returns 
     */
    setRoomState(dnRoomID,dnRoomState){
        return $ajax("PUT","/api/room/roomstate",{dnRoomID:dnRoomID,dnRoomState:dnRoomState});
    },
    /**
     * 获取空房间列表
     */
    getEmptyRoom(){
        return $ajax("GET","/api/room/emptyroom",{});
    },
    /**
     * 更换房间
     * @param {number} dnCustomerID 客户ID
     * @param {number} dnRoomID 更换后的房间ID
     * @param {number} dnPrice 新的房间价格
     * @param {number} dnDepositPrice 新的房间押金
     * @param {number} dnIsClear 是否净房
     * @returns 
     */
    setEditRoom(dnCustomerID,dnRoomID,dnPrice,dnDepositPrice,dnIsClear){
        return $ajax("PUT","/api/room/editroom",{dnCustomerID:dnCustomerID,dnRoomID:dnRoomID,dnPrice:dnPrice,dnDepositPrice:dnDepositPrice,dnIsClear:dnIsClear})
    },
    /**
     * 房间续费
     * @param {number} dnCustomerID 客户ID
     * @param {number} dnDay 续期天数
     * @param {number} dnPrice 房间价格
     * @returns 
     */
    setRenewRoom(dnCustomerID,dnDay,dnPrice){
        return $ajax("PUT","/api/room/renew",{dnCustomerID:dnCustomerID,dnDay:dnDay,dnPrice:dnPrice})
    },
    /**
     * 获取后台房间列表
     * @param {string} Search 房间号
     * @param {string} Type 房型
     * @param {number} IsShow 启用状态（0.停用，1.启用）
     * @param {number} Page 页码
     * @param {number} Limit 单页条数
     * @returns 
     */
    getAdminRoomList(Search,Type,IsShow,Page,Limit){
        return $ajax("GET","/api/admin/room",{Search:Search,Type:Type,IsShow:IsShow,Page:Page,Limit:Limit});
    },
    /**
     * 添加房间信息
     * @param {*} data 表单
     * @returns 
     */
    setAddAdminRoomList(data){
        return $ajax("POST","/api/admin/room",data);
    },
    /**
     * 修改房间信息
     * @param {*} data 表单
     * @returns 
     */
    setUpdateAdminRoomList(data){
        return $ajax("PUT","/api/admin/room",data);
    },
    /**
     * 删除房间
     * @param {number} dnRoomID 房间ID
     * @returns 
     */
    setDelAdminRoomList(dnRoomID){
        return $ajax("DELETE","/api/admin/room",{dnRoomID:dnRoomID});
    },
    /**
     * 启用禁用房间
     * @param {number} dnRoomID 房间ID
     * @param {number} dnIsShow 是否启用
     * @returns 
     */
    setAdminRoomShow(dnRoomID,dnIsShow){
        return $ajax("PUT","/api/admin/room/set",{dnRoomID:dnRoomID,dnIsShow:dnIsShow});
    },
    /**
     * 获取员工列表
     * @param {string} Search 员工姓名/用户名
     * @param {string} Type 员工类型
     * @param {number} Page 页码
     * @param {number} Limit 单页条数
     * @returns 
     */
    getAdminUserList(Search,Type,Page,Limit){
        return $ajax("GET","/api/admin/user",{Search:Search,Type:Type,Page:Page,Limit:Limit});
    },
    /**
     * 添加员工
     * @param {*} data 表单
     * @returns 
     */
    setAddAdminUserList(data){
        return $ajax("POST","/api/admin/user",data);
    },
    /**
     * 修改员工信息
     * @param {*} data 表单
     * @returns 
     */
    setUpdateAdminUserList(data){
        return $ajax("PUT","/api/admin/user",data);
    },
    /**
     * 删除员工
     * @param {number} dnUserID 员工ID
     * @returns 
     */
    setDelAdminUserList(dnUserID){
        return $ajax("DELETE","/api/admin/user",{dnUserID:dnUserID});
    },
    /**
     * 启用禁用员工
     * @param {number} dnUserID 员工ID
     * @param {number} dnIsShow 是否启用
     * @returns 
     */
    setAdminUserShow(dnUserID,dnIsShow){
        return $ajax("PUT","/api/admin/user/set",{dnUserID:dnUserID,dnIsShow:dnIsShow});
    },
    /**
     * 获取日志列表
     * @param {*} type 日志类型（user,room,customer）
     * @param {*} dnRoomID 房间ID
     * @param {*} dnCustomerID 客户ID
     * @param {*} dnUserID 员工ID
     * @param {*} dcTitle 标题
     * @param {*} dcContent 内容（模糊）
     * @param {*} Page 页码
     * @param {*} Limit 单页条数
     * @returns 
     */
    getLog(type,dnRoomID,dnCustomerID,dnUserID,dcTitle,dcContent,Page,Limit){
        return $ajax("GET","/api/admin/log",{type:type,Page:Page,Limit:Limit,dnRoomID:dnRoomID,dnCustomerID:dnCustomerID,dnUserID:dnUserID,dcTitle:dcTitle,dcContent:dcContent});
    },
    /**
     * 获取客户列表
     * @param {*} dcCustomerName 客户姓名
     * @param {*} dcRoomNumber 房间号
     * @param {*} Page 页码
     * @param {*} Limit 单页条数
     * @returns 
     */
    getCustomer(dcCustomerName,dcRoomNumber,Page,Limit){
        return $ajax("GET","/api/admin/customer",{dcCustomerName:dcCustomerName,dcRoomNumber:dcRoomNumber,Page:Page,Limit:Limit});
    },
    /**
     * 获取往日住客数量
     * @param {*} StartDate 
     * @param {*} EndDate 
     * @returns 
     */
    getCountDay(StartDate,EndDate){
        return $ajax("GET","/api/count/day",{StartDate:StartDate,EndDate:EndDate})
    },
    /**
     * 获取当天住户信息
     * @param {string} date 当天日期
     * @returns 
     */
    getCustomerCount(date){
        return $ajax("GET","/api/count/customer",{date:date})
    },
    /**
     * 获取当天更换房间记录
     * @param {string} date 当天日期
     * @param {number} Page 页码
     * @param {number} Limit 条数
     * @returns 
     */
    getEditRoomCount(date,Page,Limit){
        return $ajax("GET","/api/count/editroom",{date:date,Page:Page,Limit:Limit})
    },
    /**
     * 获取来源统计
     * @param {string} StartDate 开始时间
     * @param {string} EndDate 结束时间
     * @returns 
     */
    getComeCount(StartDate,EndDate){
        return $ajax("GET","/api/count/come",{StartDate:StartDate,EndDate:EndDate})
    },
    getRoomDetail(name) {
        return $ajax("GET", "/api/room/detail", { search: name })
    }
}


export default $webapi;