import { createApp } from 'vue'
import "./plugins/axios";
import $webapi from "./utils/webapi";
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import App from './App.vue'
import router from './router'
import $router from './router/index'
import store from './store'
import * as echarts from 'echarts'
const app = createApp(App)
.use(store)
.use(router)
.use(ElementPlus, { locale })
.mixin({
    methods: {
      $webapi: $webapi,
      $router: $router,
      $store:store
    }
})
.mount('#app')
app.echarts = echarts