const $utils = {
    getDateFC(MyTime){
        var myDate = new Date();

        if (MyTime != null) {
             if (MyTime.length > 0) {
                  myDate = new Date(MyTime.replace(/-/g, '/'));
             }
        }
        var strTime = myDate.getFullYear() + "/" + (Number(myDate.getMonth()) + 1) + "/" + myDate.getDate();
        return strTime;
    },
    getDateFCWithDate(MyTime){
        var myDate = new Date(MyTime);
        var strTime = myDate.getFullYear() + "/" + (Number(myDate.getMonth()) + 1) + "/" + myDate.getDate();
        return strTime;
    },
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),
            "m+": (date.getMonth() + 1).toString(),
            "d+": date.getDate().toString(),
            "H+": date.getHours().toString(),
            "M+": date.getMinutes().toString(),
            "S+": date.getSeconds().toString()
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
            }
        }
        return fmt;
    },
    getCardOriaTime(date) {
        // 返回格式为yyMMddHHmm
        let fmt = "yymmddHHMM";
        let ret;
        const opt = {
            "y+": date.getFullYear().toString().substring(2),
            "m+": (date.getMonth() + 1).toString(),
            "d+": date.getDate().toString(),
            "H+": date.getHours().toString(),
            "M+": date.getMinutes().toString()
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")));
            }
        }
        return fmt;
    }
}
export default $utils;