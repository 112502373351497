<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">房间号</div>
                <el-input v-model="Search" placeholder="输入房间号" style="width:120px;" :clearable="true" size="medium"></el-input>


            </div>
            <div class="search">
                <div class="label">房型</div>
                <el-select v-model="Type" placeholder="房型" value="" size="medium" style="width:140px;">
                    <el-option v-for="item in $store.state.roomTypeList" :key="item.value" :label="item.value" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="search">
                <div class="label">房间状态</div>
                <el-select v-model="State" placeholder="房间状态" value="" size="medium" style="width:140px;">
                    <el-option label="启用" value="1">启用</el-option>
                    <el-option label="停用" value="0">停用</el-option>
                </el-select>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
            <div class="flex1"></div>
            <el-button type="primary" size="medium" icon="el-icon-plus" @click="Add">添加房间</el-button>
        </div>

        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="mini" :max-height="windowHeight-250" v-loading="isload" >
                <el-table-column fixed align="center" prop="dcRoomNumber" label="房间号" width="100"></el-table-column>
                <el-table-column align="center" prop="dcRoomName" label="房型" width="120"></el-table-column>
                <el-table-column align="center" label="当前房态" width="120">
                    <template #default="scope">
                        <div :class="'room-fc-state-'+scope.row.dnRoomState">{{ getState(scope.row.dnRoomState) }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="dnPrice" label="房间价格" width="120"></el-table-column>
                <el-table-column align="center" prop="dnTimePrice" label="钟点房价格" width="100"></el-table-column>
                <el-table-column align="center" prop="dnDepositPrice" label="押金" width="100"></el-table-column>
                <el-table-column align="center" prop="dnBulidingNumber" label="楼栋号" width="100"></el-table-column>
                <el-table-column align="center" prop="dnFloorNumber" label="楼层号" width="100"></el-table-column>
                <el-table-column align="center" prop="dnChildNumber" label="子房间" width="100"></el-table-column>
                <el-table-column align="center" prop="dtAddTime" label="添加时间" width="200"></el-table-column>
                <el-table-column align="center" prop="dtEditTime" label="修改时间" width="200"></el-table-column>
                <el-table-column align="center" prop="dnIsShow" label="状态" fixed="right" width="100">
                    <template #default="scope">
                        <div class="fc-success fw-b" v-show="scope.row.dnIsShow == 1" >启用</div>
                        <div class="fc-error fw-b" v-show="scope.row.dnIsShow == 0">停用</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" width="200">
                    <template #default="scope">
                        <el-button type="text" size="small" @click="Edit(scope.row)">编辑</el-button>
                        <el-button type="text" size="small" v-show="scope.row.dnIsShow == 1" @click="setRoomShow(scope.row.dnRoomID,0)">停用</el-button>
                        <el-button type="text" size="small" v-show="scope.row.dnIsShow == 0" @click="setRoomShow(scope.row.dnRoomID,1)">启用</el-button>
                        <el-popconfirm title="确定删除该房间吗？" @confirm="Del(scope.row.dnRoomID)">
                            <template #reference>
                                <el-button type="text" size="small" >删除</el-button>
                            </template>
                        </el-popconfirm>
                        <el-button type="text" size="small" @click="ShowLog(scope.row)">查看日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages">
                <el-pagination @size-change="updatesize" @current-change="update" :current-page="Page+1" :page-sizes="[10,20,30,40,50,60]" :page-size="Limit" layout="total, sizes, prev, pager, next, jumper" :total="Count"></el-pagination>
            </div>
        </div>
        <el-drawer :title="roomform.dnRoomID>0?'编辑房间':'添加房间'" v-model="roomDrawer" direction="rtl" destroy-on-close :before-close="handleClose" :size="600">
            <addRoomForm :_roomform="roomform" @update="edittrue" @close="roomDrawer=false"></addRoomForm>
        </el-drawer>
        <el-dialog title="房间日志查询" v-model="dialog_log" :width="1200" :destroy-on-close="true">
            <log :dnRoomID="dnRoomID" @close="dialog_log=false"></log>
        </el-dialog>
    </div>
</template>

<script>
import addRoomForm from "../../components/addRoom";
import log from "./log/room";
import $webapi from "../../utils/webapi";
export default {
    name:"room",
    components:{addRoomForm,log},
    created(){
        this.bindData();
    },
    data(){
        return{
            Search:"",
            Type:"",
            State:"",
            Page:0,
            Limit:10,
            Count:0,
            isload:false,
            roomDrawer:false,
            roomform:{
                dnLockType: 1,
                dcRoomCode: "",
                dnRoomID:0,
                dnBulidingNumber:"",
                dnFloorNumber:"",
                dcRoomNumber:"",
                dcRoomName:"",
                dnPrice:"",
                dnTimePrice:"",
                dnDepositPrice:"",
                dnIsShow:true
            },
            windowHeight: document.documentElement.clientHeight,
            tableData: [],
            dialog_log:false,
            dnRoomID:0
        }
    },
    methods:{
        GoSearch(){
            this.Page = 0;
            this.bindData();
        },
        ReSearch(){
            this.Page = 0;
            this.Search = "";
            this.Type = "";
            this.State = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getAdminRoomList(this.Search,this.Type,this.State,this.Page,this.Limit).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data.roomlist;
                    _this.Count = ret.data.count;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
        setRoomShow(dnRoomID,dnIsShow){
            let _this = this;
            $webapi.setAdminRoomShow(dnRoomID,dnIsShow).then(ret=>{
                if(ret.success){
                    _this.$message.success(ret.message);
                    _this.bindData();
                }
                else{
                    _this.$message.error(ret.message);
                }
            }).catch(err=>{
                _this.$message.error("操作失败，请重试");
            })
        },
        Del(dnRoomID){
            let _this = this;
            $webapi.setDelAdminRoomList(dnRoomID).then(ret=>{
                if(ret.success){
                    _this.$message.success(ret.message);
                    _this.bindData();
                }
                else{
                    _this.$message.error(ret.message);
                }
            }).catch(err=>{
                _this.$message.error("删除失败，请重试");
            })
        },
        update(page){
            this.Page = page-1;
            this.bindData();
        },
        updatesize(Limit){
            this.Limit = Limit;
            this.bindData();
        },
        ShowLog(item){
            this.dnRoomID = item.dnRoomID;
            this.dialog_log = true;
        },
        renderHeader(h,para) {
            //该列的绑定数据
        },
        getState(state){
            var retStr = "";
            switch(Number(state)){
                case 1 : retStr = "空房";break;
                case 2 : retStr = "已入住";break;
                case 3 : retStr = "脏房";break;
                case 4 : retStr = "自用";break;
                case 5 : retStr = "预定";break;
                case 6 : retStr = "维修";break;
                default : retStr = "未知";break;
            }
            return retStr;
        },
        edittrue(){
            this.bindData();
            this.roomDrawer = false;
        },
        Edit(item){
            this.roomform.dnRoomID = Number(item.dnRoomID);
            this.roomform.dnBulidingNumber = Number(item.dnBulidingNumber);
            this.roomform.dnFloorNumber = Number(item.dnFloorNumber);
            this.roomform.dcRoomNumber = Number(item.dcRoomNumber);
            this.roomform.dcRoomName = item.dcRoomName;
            this.roomform.dnLockType = Number(item.dnLockType);
            this.roomform.dcRoomCode = item.dcRoomCode;
            this.roomform.dnPrice = Number(item.dnPrice);
            this.roomform.dnTimePrice = Number(item.dnTimePrice);
            this.roomform.dnDepositPrice = Number(item.dnDepositPrice);
            this.roomform.dnIsShow = item.dnIsShow == 1
            this.roomDrawer = true;
        },
        Add(){
            this.roomform.dnRoomID = 0;
            this.roomform.dnBulidingNumber = "";
            this.roomform.dnFloorNumber = "";
            this.roomform.dcRoomNumber = "";
            this.roomform.dcRoomName = "";
            this.roomform.dnLockType = 1;
            this.roomform.dcRoomCode = "";
            this.roomform.dnPrice = "";
            this.roomform.dnTimePrice = "";
            this.roomform.dnDepositPrice = "";
            this.roomform.dnIsShow = true;
            this.roomDrawer = true;
        }
    },
    computed:{
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
            
        };
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;

        }
        .form{
            padding: 30px;
            padding-top: 0;
        }
    }
</style>