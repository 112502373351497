<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%">
      <el-aside width="200px" style="height:100%">
        <el-menu style="height:100%"
            :router="true"
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
        >
      <div class="title"><i class="el-icon-s-operation"></i>&nbsp;管理维护&nbsp;&nbsp;&nbsp;</div>
          <el-menu-item index="/admin/room" v-if="dcPower.indexOf('房间管理') > -1">
            <i class="el-icon-menu"></i>
            <template #title>房间管理</template>
          </el-menu-item>
          <el-menu-item index="/admin/user" v-if="dcPower.indexOf('员工管理') > -1">
            <i class="el-icon-user"></i>
            <template #title>员工管理</template>
          </el-menu-item>
          <el-menu-item index="/admin/customer" v-if="dcPower.indexOf('客户管理') > -1">
            <i class="el-icon-user"></i>
            <template #title>客户管理</template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "admin",
  created(){
    this.dcPower = window.localStorage.getItem("dcPower");
  },
  data(){
    return {
      dcPower : ""
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
}
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.el-main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}
.title{
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
</style>