import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import login from '../views/login/login'
import index from '../views/index/index'
import state from '../views/state/state'
import admin from '../views/admin/index'
import adminuser from '../views/admin/user'
import adminroom from '../views/admin/room'
import admincustomer from '../views/admin/customer'
import card from '../views/card/card'
import count from '../views/count/index'
import count_main from '../views/count/main'
import count_customer from '../views/count/customer'
import count_editroom from '../views/count/editroom'


const routes = [{
		path: '/',
		name: '',
		component: login
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/index',
		name: "index",
		component: index,
		children: [{
			path: "/index",
			name: "state",
			component: state
		}, {
			path: "/card",
			name: "card",
			component: card,
		},{
			path: "/count",
			name: "count",
			redirect: '/count/main',
			component: count,
			children:[{
				path:"/count/main",
				name:"/count/main",
				component:count_main
			},{
				path:"/count/customer",
				name:"/count/customer",
				component:count_customer
			},{
				path:"/count/editroom",
				name:"/count/editroom",
				component:count_editroom
			}]
		}, {
			path: "/admin",
			name: "admin",
			redirect: '/admin/room',
			component: admin,
			children: [{
				path:"/admin/user",
				name:"/admin/user",
				component:adminuser
			},{
				path:"/admin/room",
				name:"/admin/room",
				component:adminroom
			},{
				path:"/admin/customer",
				name:"/admin/customer",
				component:admincustomer
			}]
		}]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
});
router.beforeEach(to => {
	if(to.path != "/login" && to.path != "/" && !window.localStorage.getItem("dcToken")) {
		router.push("/")
	}
});

export default router