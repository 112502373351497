<template>
    <div class="main">
        <div class="input-label">
            <div class="search">
                <div class="label">搜索</div>
                <el-select v-model="dcTitle" placeholder="日志类别" value="" size="medium" style="width:140px;">
                    <el-option label="开房" value="开房"></el-option>
                    <el-option label="更换房间" value="更换房间"></el-option>
                    <el-option label="房间续费" value="房间续费"></el-option>
                </el-select>
            </div>
            <div class="search">
                <el-input v-model="Search" placeholder="搜索日志内容" style="width:200px;" :clearable="true" size="medium"></el-input>
            </div>
            <el-button type="primary" size="medium" icon="el-icon-search" @click="GoSearch">查询</el-button>
            <el-button type="" size="medium" icon="el-icon-refresh" @click="ReSearch">重置</el-button>
        </div>
        <div class="table">
            <el-table :data="tableData" border :stripe="true" size="small" :max-height="400" v-loading="isload" >
              <el-table-column fixed type="expand">
                  <template #default="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <template v-if="props.row.user">
                        <el-form-item label="员工ID"><span>{{ props.row.user.dnUserID }}</span></el-form-item>
                        <el-form-item label="员工姓名"><span>{{ props.row.user.dcName }}</span></el-form-item>
                      </template>
                      <template v-if="props.row.room">
                        <el-form-item label="房间ID"><span>{{ props.row.room.dnRoomID }}</span></el-form-item>
                        <el-form-item label="房间"><span>{{ props.row.room.dcRoomNumber }} - {{ props.row.room.dcRoomName }}</span></el-form-item>
                      </template>
                      <template v-if="props.row.customer">
                        <el-form-item label="客户ID"><span>{{ props.row.customer.dnCustomerID }}</span></el-form-item>
                        <el-form-item label="客户姓名"><span>{{ props.row.customer.dcCustomerName }}</span></el-form-item>
                        <el-form-item label="客户来源"><span>{{ props.row.customer.dcCome }}</span></el-form-item>
                        <el-form-item label="证件类型"><span>{{ props.row.customer.dcCodeType }}</span></el-form-item>
                        <el-form-item label="证件号"><span>{{ props.row.customer.dcCode }}</span></el-form-item>
                        <el-form-item label="手机号"><span>{{ props.row.customer.dcPhone }}</span></el-form-item>
                        <el-form-item label="入住时间"><span>{{ props.row.customer.dtInTime }}</span></el-form-item>
                        <el-form-item label="入住天数"><span>{{ props.row.customer.dnDay }}</span></el-form-item>
                        <el-form-item label="预离时间"><span>{{ props.row.customer.dtOutTime }}</span></el-form-item>
                        <el-form-item label="入住价格"><span>{{ props.row.customer.dnPrice }}</span></el-form-item>
                        <el-form-item label="入住押金"><span>{{ props.row.customer.dnDepositPrice }}</span></el-form-item>
                      </template>
                    </el-form>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="dnLogID" label="日志ID" width="58"></el-table-column>
                <el-table-column align="center" prop="customer.dcCustomerName" label="入住人" width="100"></el-table-column>
                <el-table-column align="center" prop="customer.dtInTime" label="入住时间" width="150"></el-table-column>
                <el-table-column align="center" prop="customer.dnDay" label="入住天数" width="80"></el-table-column>
                <el-table-column align="center" prop="customer.dtOutTime" label="预离时间" width="150"></el-table-column>
                <el-table-column align="center" prop="room.dcRoomNumber" label="房间号" width="70"></el-table-column>
                <el-table-column align="center" prop="room.dcRoomName" label="房型" width="100"></el-table-column>
                <el-table-column align="center" prop="user.dcName" label="操作人" width="100"></el-table-column>
                <el-table-column align="center" prop="dcTitle" label="标题" width="140"></el-table-column>
                <el-table-column prop="dcContent" label="内容" width="500"></el-table-column>
                <el-table-column fixed="right" align="center" prop="dtAddTime" label="时间" width="150"></el-table-column>
            </el-table>
            <div class="pages">
                <el-pagination @size-change="updatesize" @current-change="update" :current-page="Page+1" :page-sizes="[10,20,30,40,50,60]" :page-size="Limit" layout="total, sizes, prev, pager, next, jumper" :total="Count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import $webapi from "../../../utils/webapi";
export default {
    name:"room",
    created(){
        this.bindData();
    },
    data(){
        return{
            Search:"",
            dcTitle:"",
            Page:0,
            Limit:10,
            Count:0,
            isload:false,
            windowHeight: document.documentElement.clientHeight,
            tableData: []
        }
    },
    methods:{
        GoSearch(){
            this.Page = 0;
            this.bindData();
        },
        ReSearch(){
            this.Page = 0;
            this.Search = "";
            this.dcTitle = "";
            this.bindData();
        },
        bindData(){
            let _this = this;
            _this.isload = true;
            $webapi.getLog("customer","",this.dnCustomerID,"",this.dcTitle,this.Search,this.Page,this.Limit).then(ret=>{
                _this.isload = false;
                if(ret.success){
                    _this.tableData = ret.data.list;
                    console.log(ret.data.list);
                    _this.Count = ret.data.count;
                }
            }).catch(err=>{
                _this.isload = false;
            });
        },
        update(page){
          this.Page = page-1;
          this.bindData();
        },
        updatesize(Limit){
            this.Limit = Limit;
            this.bindData();
        },
        renderHeader(h,para) {
            //该列的绑定数据
        },
    },
    computed:{
    },
    mounted() {
        var that = this;
        // <!--把window.onresize事件挂在到mounted函数上-->
        window.onresize = () => {
            return (() => {
                window.fullHeight = document.documentElement.clientHeight;
                that.windowHeight = window.fullHeight;  // 高
            })()
            
        };
    },
    props: {
        dnCustomerID:String
    },
}
</script>

<style lang="scss" scoped>
    el-input{
        width: auto;
    }
    .main{
        display: flex;
        flex-direction: column;
        width: 100%;
        .input-label {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px;
            .search{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;
                .label{
                    width: auto;
                    margin: 0 10px 0 10px;
                    color: #777;
                }
            }
            
        }
        .table{
            margin: 0 30px;
            .fd-r{
              justify-content: center;
              div{
                padding: 1px 6px;
                margin: 0 3px;
                border: solid 1px #eee;
                border-radius: 5px;
                background: #eee;
                height: 26px;
                line-height: 24px;
              }
            }
        }
        .pages{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 10px;
            align-items: center;
        }
    }


  .demo-table-expand {
    width: 500px;
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>