<template>
  <div class="main">
    <el-scrollbar class="scrollbar" height="100%">
      <div class="top" style="margin-top:10px;">
		<div style="height:10px;"></div>
        <div class="card">
          <div class="l">
            <div class="title">今日新客</div>
            <div class="count">{{ n }}人</div>
          </div>
          <div class="r" v-if="reload" id="count_main_n"></div>
        </div>
        <div class="card">
          <div class="l">
            <div class="title">今日旧客</div>
            <div class="count">{{ o }}人</div>
          </div>
          <div class="r" v-if="reload" id="count_main_o"></div>
        </div>
        <div class="card">
          <div class="l">
            <div class="title">今日退房</div>
            <div class="count">{{ b }}人</div>
          </div>
          <div class="r" v-if="reload" id="count_main_b"></div>
        </div>
      </div>
      <div class="top">
        <div
          class="card"
          style="width: 800px; height: 300px; flex-direction: column"
        >
          <div id="myChart" v-if="reload" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="top">
        <div
          class="card"
          style="width: 800px; height: 300px; flex-direction: column"
        >
          <div id="chart_come" v-if="reload" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import $webapi from "../../utils/webapi";
import $utils from "../../utils/utils";
export default {
  created() {
	  this.reload = true;
    this.BindDatas();
  },
  data() {
    return {
      n: 0,
      o: 0,
      b: 0,
	  reload:false
    };
  },
  mounted() {},
  methods: {
    BindDatas() {
      let _this = this;
      var end = new Date();
      var start = end.setDate(end.getDate() - 13);
      start = new Date(start);
      $webapi
        .getCountDay(
          $utils.getDateFCWithDate(start),
          $utils.getDateFCWithDate(new Date())
        )
        .then((ret) => {
          if (ret.success) {
            var t = [],
              n = [],
              o = [],
              b = [];
            var st = [],
              sn = [],
              so = [],
              sb = [];
            for (let i = 0; i < ret.data.length; i++) {
              t.push(ret.data[i].t);
              n.push(ret.data[i].n);
              o.push(ret.data[i].o);
              b.push(ret.data[i].b);
              if (i > 6) {
                st.push(ret.data[i].t);
                sn.push(ret.data[i].n);
                so.push(ret.data[i].o);
                sb.push(ret.data[i].b);
              }
            }
            _this.n = ret.data[ret.data.length - 1].n;
            _this.o = ret.data[ret.data.length - 1].o;
            _this.b = ret.data[ret.data.length - 1].b;
            _this.BindECharts(t, n, o, b);

            _this.BindSmallEcharts("count_main_n", st, sn, "#86c66866");
            _this.BindSmallEcharts("count_main_o", st, so, "#f9c24966");
            _this.BindSmallEcharts("count_main_b", st, sb, "#ff1c0966");
          }
        })
        .catch((err) => {});

      $webapi
        .getComeCount(
          $utils.getDateFCWithDate(start),
          $utils.getDateFCWithDate(new Date())
        )
        .then((ret) => {
          if (ret.success) {
            _this.BindEchartsCome(
              ret.data.dtime,
              ret.data.qiantai,
              ret.data.xiecheng,
              ret.data.quna,
              ret.data.meituan,
              ret.data.feizhu,
              ret.data.yilong,
              ret.data.other
            );
          }
        })
        .catch((err) => {});
    },
    BindSmallEcharts(id, days, val, color) {
      let myChart = this.$root.echarts.init(document.getElementById(id));
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: "category",
            data: days,
            splitLine: {
              show: false,
            },
            axisLine: {
              //y轴
              show: false,
            },
            axisTick: {
              //y轴刻度线
              alignWithLabel: true,
              show: false,
            },
            axisLabel: {
              show: false, // 坐标轴刻度标签的显示间隔
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
          },
        ],
        series: [
          {
            name: "人数",
            type: "bar",
            barWidth: "90%",
            itemStyle: {
              normal: {
                color: color,
              },
            },
            data: val,
          },
        ],
      });
    },
    BindEchartsCome(
      days,
      qiantai,
      xiecheng,
      quna,
      meituan,
      feizhu,
      yilong,
      other
    ) {
      let myChart = this.$root.echarts.init(
        document.getElementById("chart_come")
      );
      myChart.setOption({
        title: {
          text: "过去两周来源",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["前台", "携程", "去哪儿", "美团", "飞猪", "同程艺龙", "其他"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: days,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "前台",
            type: "line",
            data: qiantai,
          },
          {
            name: "携程",
            type: "line",
            data: xiecheng,
          },
          {
            name: "去哪儿",
            type: "line",
            data: quna,
          },
          {
            name: "美团",
            type: "line",
            data: meituan,
          },
          {
            name: "飞猪",
            type: "line",
            stack: "人",
            data: feizhu,
          },
          {
            name: "同程艺龙",
            type: "line",
            data: yilong,
          },
          {
            name: "其他",
            type: "line",
            data: other,
          },
        ],
      });
    },
    BindECharts(days, n, o, b) {
      let myChart = this.$root.echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "过去两周入住",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["新增", "旧客", "退房"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: days,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "新增",
            type: "bar",
            stack: "住客",
            barWidth: "80%",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#86c668",
              },
            },
            data: n,
          },
          {
            name: "旧客",
            type: "bar",
            stack: "住客",
            barWidth: "80%",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#f9c249",
              },
            },
            data: o,
          },
          {
            name: "退房",
            type: "bar",
            stack: "住客",
            barWidth: "80%",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "#ff1c09",
              },
            },
            data: b,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  .scrollbar {
	  padding: 0 10px;
    .top {
      display: flex;
      flex-direction: row;
    }
    .card {
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin: 10px;
      width: 227px;
      .l {
        width: 80px;
      }
      .r {
        flex: 1;
        height: 100%;
      }
      .title {
        font-size: 14px;
        color: #999;
      }
      .count {
        margin-top: 10px;
        font-size: 20px;
        color: #666;
        font-weight: bold;
      }
    }
  }
}
</style>