<template>
    <div class="form">
        <el-form label-width="auto" ref="form" :model="form" :rules="rules" status-icon validate-on-rule-change>
            <el-row :gutter="20">
                <el-col :span="18">
                    <el-form-item label="房间">
                        <div class="fs-20 room-fc-state-1">{{form.dcRoomNumber}} - {{form.dcRoomName}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="入住人" prop="dcCustomerName">
                        <el-input v-model="form.dcCustomerName" placeholder="请填写入住人姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="来源" prop="dcCome">
                        <el-select v-model="form.dcCome" placeholder="请选择" value="前台">
                            <el-option v-for="item in comelist" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="证件" prop="dcCode">
                        <el-input v-model="form.dcCode" placeholder="请填写入住人证件号">
                            <template #prepend>
                                <el-select v-model="form.dcCodeType" placeholder="请选择" style="width:100px;">
                                    <el-option v-for="item in codetypelist" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号码" prop="dcPhone">
                        <el-input v-model="form.dcPhone" placeholder="请填写入住人手机号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12"></el-col>
                <el-col :span="12">
                    <el-form-item label="入住天数" prop="dnDay">
                        <el-input-number v-model.number="form.dnDay"  :min="1" :max="3650" label="描述文字" style="width:165px;"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="预离日期" prop="dtOutTime">
                        <el-date-picker v-model="form.dtOutTime" :editable="false" align="right" type="date" :clearable="false" placeholder="选择日期" style="width:165px;" :picker-options="pickerOptions"> </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="房价" prop="dnPrice">
                        <el-input v-model.number="form.dnPrice">
                            <template #append>
                                <div>元</div>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="应收房费">
                        <div class="fs-20 room-fc-state-1 fw-b">{{(form.dnDay*form.dnPrice)}}元</div> 
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="押金" prop="dnDepositPrice">
                        <el-input v-model.number="form.dnDepositPrice">
                            <template #append><div>元</div></template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="实际收费">
                        <div class="fs-20 room-fc-state-1 fw-b">{{(form.dnDay*form.dnPrice)+Number(form.dnDepositPrice)}}元</div> 
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="是否制卡"><el-switch
                        v-model="form.dnIsCard"
                        active-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="发卡机">
                        {{ $store.state.lockTypeList.find(x => x.key == form.dnLockType).value }}
                    </el-form-item>
                </el-col>
                
                <el-col :span="24">
                    <el-button type="primary" @click="submitSave" style="width:120px;" :loading="isSubmit">确定</el-button>
                    <el-button type="info" plain @click="handleClose" style="width:120px;">取消</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import $utils from "../utils/utils";
import $webapi from "../utils/webapi";
export default {
    created(){
        var dateTime=new Date();/**获取当前时间 */
        var jian = 0;/**需要减去的天数（如果在当天六点前开房算昨日开房，需要在当日退房） */
        if(dateTime.getHours() < 6){
            jian = 1;
        }
        dateTime = new Date(dateTime.setDate(dateTime.getDate()+1-jian));/**预离日期（当前时间+入住天数-当日过早时间） */
        this.isUpdate = false;
        this.form.dtOutTime = dateTime;
    },
    data(){
        return {
            form:{
                dnRoomID:0,
                dcRoomNumber:"",
                dcRoomName:"",
                dcCustomerName:"",
                dnDay:1,
                dcCome: "前台",
                dcCodeType:"身份证",
                dcCode:"",
                dnIsCard:true,
                dnPrice:0,
                dnDepositPrice:0,
                dcPhone:"",
                dtOutTime:""
            },
            rules:{
                dcCustomerName:[{ required: true, message: '姓名不能为空'}],
                dnDay:[{ required: true, message: '请选择入住天数'},{ type: 'number', message: '天数必须为数字'}],
                dnPrice:[{ required: true, message: '请填写房价'},{ type: 'number', message: '房价必须为数字'}],
                dnDepositPrice:[{ required: true, message: '请填写押金'},{ type: 'number', message: '押金必须为数字'}],
                dtOutTime:[{ required: true, message: '请选择预离日期'}],
            },
            isSubmit:false,
            codetypelist:[{
                value: '身份证',
                label: '身份证'
            }, {
                value: '工作证',
                label: '工作证'
            }, {
                value: '军官证',
                label: '军官证'
            }, {
                value: '护照',
                label: '护照'
            }, {
                value: '驾驶证',
                label: '驾驶证'
            }],
            comelist: [{
                value: '前台',
                label: '前台'
            }, {
                value: '携程',
                label: '携程'
            }, {
                value: '去哪',
                label: '去哪'
            }, {
                value: '美团',
                label: '美团'
            }, {
                value: '飞猪',
                label: '飞猪'
            }, {
                value: '艺龙',
                label: '艺龙'
            }, {
                value: '其他',
                label: '其他'
            }],
            isUpdate:true
        }
    },
    computed:{
        pricecount(){
            return this.price*this.day;
        }
    },
    props: {
        _form:Object
    },
    watch: {
        _form: { immediate: true, 
            handler (val) {
                this.form = val 
            }
        },
        'form.dnDay':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                if(this.isUpdate){
                    var dateTime=new Date();/**获取当前时间 */
                    var jian = 0;/**需要减去的天数（如果在当天六点前开房算昨日开房，需要在当日退房） */
                    if(dateTime.getHours() < 6){
                        jian = 1;
                    }
                    dateTime = new Date(dateTime.setDate(dateTime.getDate()+newName-jian));/**预离日期（当前时间+入住天数-当日过早时间） */
                    this.isUpdate = false;
                    this.form.dtOutTime = dateTime;
                }else{
                    this.isUpdate = true;
                }
            },
        },
        'form.dtOutTime':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                if(this.isUpdate){
                    var thisTime = new Date();
                    var dateTime=new Date($utils.getDateFC());
                    if(thisTime.getHours() < 6){
                        dateTime = new Date($utils.getDateFCWithDate(thisTime.setDate(dateTime.getDate()-1)));
                    }
                    var day = Math.floor(Math.abs(newName - dateTime) / 1000 / 60 / 60 /24);
                    this.isUpdate = false;
                    this.form.dnDay = day;
                }else{
                    this.isUpdate = true;
                }
            },
        }
    },
    methods:{
        submitSave(){
            let _this = this;
            this.$refs["form"].validate(volid =>{
                if(volid){
                    _this.isSubmit = true;
                    if(_this.form.dnIsCard){
                        console.log(this.form)
                        let isTrue = false
                        switch(this.form.dnLockType) {
                            case 1:
                                isTrue = this.anlockCard()
                                break;
                            case 2:
                                isTrue = this.cardoriaCard()
                                break;
                        }
                        if(!isTrue) return
                    }
                    var data = JSON.parse(JSON.stringify(_this.form));
                    data.dtOutTime = $utils.getDateFCWithDate(data.dtOutTime)+" 13:00:00";
                    $webapi.setTookRoom(data).then(ret=>{
                        if(ret.success){
                            _this.$message.success(ret.message);
                            _this.$emit('update');
                        }
                        else{
                            _this.isSubmit = false;
                            _this.$message.error(ret.message);
                        }
                    }).catch(err=>{
                        _this.isSubmit = false;
                        _this.$message.error("开房失败，请重试");
                    });
                }
                else{
                    return false;
                }
            })
        },
        anlockCard() {
            try{
                let card = window.callClient.setWriteCard(
                    1,
                    this.form.dcRoomNumber[0],
                    this.form.dcRoomNumber,
                    $utils.getDateFCWithDate(this.form.dtOutTime)+" 13:00:00",
                    true
                );
                if(card != "1" && card != 1){
                    this.isSubmit = false;
                    this.$message.error("开房失败，写卡失败");
                    return false
                }
            }
            catch{
                this.isSubmit = false;
                this.$message.error("客户端外不支持制卡");
                return false
            }
            return true
        },
        cardoriaCard() {
            try{
                console.log(`${this.form.dtOutTime} 13:00:00`)
                const startTime = $utils.getCardOriaTime(new Date());
                const endTime = $utils.getCardOriaTime(new Date(`${$utils.dateFormat("YYYY/mm/dd", this.form.dtOutTime)} 13:00:00`));
                let res = window.cardOria.guestCard(
                    startTime,
                    endTime,
                    this.form.dcRoomCode
                );
                if(Number(res) != 0){
                    this.isSubmit = false;
                    this.$message.error("开房失败，写卡失败");
                    return false
                }
            }
            catch{
                this.isSubmit = false;
                this.$message.error("客户端外不支持制卡");
                return false
            }
            return true
        },
        handleClose(){
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    .form{
        padding: 30px;
        padding-top: 0;
    }
</style>