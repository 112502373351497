/*
 * @Author: huihuangzbgxc@qq.com gxc@7ucloud.com
 * @Date: 2021-04-29 14:46:50
 * @LastEditors: huihuangzbgxc@qq.com gxc@7ucloud.com
 * @LastEditTime: 2024-04-01 14:49:08
 * @FilePath: /hotel-room-management-system-1/src/plugins/axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";

// axios.defaults.baseURL = 'http://hplqy.suoluomei.cn/index.php?s=/hfs/Api/'  //正式
// axios.defaults.baseURL = 'http://gxcex.cn:65081/' //测试
//post请求头
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
axios.defaults.timeout = 10000;

axios.defaults.withCredentials = false;
axios.interceptors.request.use(
  config => {
    config.headers['token'] = window.localStorage.getItem("dcToken");
    config.headers["contentType"] = "application/x-www-form-urlencoded";
    //请求拦截
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    //网络请求成功时
    return response;
  },
  error => {
    //网络请求失败时 

    return error;
  }
);