/*
 * @Author: huihuangzbgxc@qq.com gxc@7ucloud.com
 * @Date: 2021-05-03 13:24:36
 * @LastEditors: huihuangzbgxc@qq.com gxc@7ucloud.com
 * @LastEditTime: 2024-04-01 17:28:50
 * @FilePath: /hotel-room-management-system-1/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    lockTypeList: [
      { key: 1, value: "安诺克" },
      { key: 2, value: "卡多利亚" },
    ],
    roomTypeList:[{
      value:"大床房"
    },{
      value:"标准间"
    },{
      value:"双人间"
    },{
      value:"三人间"
    },{
      value:"四人间"
    },{
      value:"大床+小床"
    },{
      value:"套房"
    }],
    userTypeList:[{
      value:"前台"
    },{
      value:"服务员"
    },{
      value:"管理员"
    }]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
