<template>
  <div class="card">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="房间号">
        <el-input
          v-model="form.dcRoomNumber"
          placeholder="请输入房间号"
          style="width: 165px"
        ></el-input>
      </el-form-item>
      <el-form-item label="天数" prop="dnDay">
          <el-input-number v-model.number="form.dnDay"  :min="1" :max="3650" label="描述文字" style="width:165px;"></el-input-number>
      </el-form-item>
      <el-form-item label="失效日期" prop="dtOutTime">
          <el-date-picker v-model="form.dtOutTime" :editable="false" align="right" type="date" placeholder="选择日期" style="width:165px;" :shortcuts="shortcuts" :default-value="new Date()" :clearable="false" :picker-options="pickerOptions"> </el-date-picker>
      </el-form-item>
      <el-form-item label="作废旧卡">
        <el-switch v-model="form.idOld"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">写卡</el-button>
        <el-button type="success" @click="read">读卡</el-button>
        <el-button type="danger" @click="clear">销卡</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      title="读卡成功"
      v-model="dialogVisible"
      width="300"
      :before-close="handleClose"
    >
      <el-descriptions class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template #label>房间号</template>
          {{ roomNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>卡权限</template>
          {{ isout == 1 ? "使旧卡作废" : "无特殊权限" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>入住时间</template>
          {{ intime }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>到期时间</template>
          {{ outtime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import $utils from "../../utils/utils";
import $webapi from '../../utils/webapi';
export default {
  created() {
    let _this = this;
    window.qwe = function (qwe) {
      //ReadOK,032A3D334F,1,1,3,333,255,2021/5/9 0:20:00,2021/5/3 0:20:00,1
      var readinfo = qwe.split(",");
      if (readinfo.length == 10) {
        _this.roomNumber = readinfo[5];
        _this.intime = readinfo[7];
        _this.outtime = readinfo[8];
        _this.isout = readinfo[2];
        _this.dialogVisible = true;
      } else if (readinfo.length == 2) {
        //失败
        _this.$message.error("读卡失败");
      } else if (readinfo.length == 3) {
        //空卡
        _this.$message.error("该卡为空卡");
      }
    };
    _this.dnDay = 1;
  },
  data() {
    return {
      isUpdate:false,
      form: {
        dcRoomNumber: "",
        idOld: true,
        dnDay:0,
        dtOutTime:"",
        value1: [
          new Date(),
          new Date(new Date().setDate(new Date().getDay() + 3)),
        ],
      },
      shortcuts: [{
          text: '明天',
          value: (() => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            return date
          })(),
        }, {
          text: '一周后',
          value: (() => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            return date
          })(),
        }, {
          text: '一个月后',
          value: (() => {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            return date
          })(),
        }],
      roomNumber: "",
      intime: "",
      outtime: "",
      isout: "",
      dialogVisible: false,
    };
  },
  methods: {
    onSubmit() {
      try {
        let _this = this;
        if(_this.form.dcRoomNumber == ""){
          _this.$message.error("请填写房间号");return;
        }
        var intime = $utils.dateFormat("YYYY/mm/dd HH:MM:SS", new Date());
        var outtime = $utils.dateFormat("YYYY/mm/dd HH:MM:SS", _this.form.dtOutTime);
        $webapi.setAddCardLog("开卡房间"+_this.form.dcRoomNumber+","+outtime+"失效。").then(ret=>{}).catch(err=>{});
          window.callClient.setWriteCardUser(
            1,
            _this.form.dcRoomNumber[0],
            _this.form.dcRoomNumber,
            intime,
            outtime,
            _this.form.idOld
          );
        } catch {
          this.$message.error("客户端外不支持写卡");
        }
    },
    async read() {
      try {
        let cardNum = window.cardOria.readCard();
        if(cardNum != "") {
          if (cardNum != "7F7F7F") {
            const res = await $webapi.getRoomDetail(cardNum)
            if (res.success) {
              this.roomNumber = res.data.dcRoomNumber;
              this.intime = res.data.dtInTime;
              this.outtime = res.data.dtOutTime;
              this.isout = 0;
              this.dialogVisible = true;
            } else {
              this.$message.error(res.message);
            }
            console.log(res)
          }
        } else {
          window.callClient.readCard();
        }
      } catch {
        this.$message.error("客户端外不支持读卡");
      }
    },
    clear() {
      try {
        window.cardOria.cardErase();
        window.callClient.setClearCard();
      } catch {
        this.$message.error("客户端外不支持销卡");
      }
    },
  },

    watch: {
        'form.dnDay':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                    var dateTime=new Date();/**获取当前时间 */
                    var jian = 0;/**需要减去的天数（如果在当天六点前开房算昨日开房，需要在当日退房） */
                    if(dateTime.getHours() < 6){
                        jian = 1;
                    }
                    dateTime = new Date(dateTime.setDate(dateTime.getDate()+newName-jian));/**预离日期（当前时间+入住天数-当日过早时间） */
                    this.isUpdate = false;
                    this.form.dtOutTime = dateTime;
            },
        },
        'form.dtOutTime':{
            handler(newName, oldName){//name属性内部属性发生变化之后，会先执行handler方法
                    var thisTime = new Date();
                    var dateTime=new Date($utils.getDateFC());
                    if(thisTime.getHours() < 6){
                        dateTime = new Date($utils.getDateFCWithDate(thisTime.setDate(dateTime.getDate()-1)));
                    }
                    var day = Math.floor(Math.abs(newName - dateTime) / 1000 / 60 / 60 /24);
                    this.isUpdate = false;
                    this.form.dnDay = day;
            },
        }
    },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 50px;
}
</style>